.overflow-menu {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;

  > span {
    vertical-align: middle;
  }
}

.overflow-menu--top-right {
  position: absolute;
  top: 0;
  right: 0;
}
