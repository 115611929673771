$default-font-family: 'V-Inter', 'Helvetica Neue', 'Helvetica-Neue', 'Arial', sans-serif;

// Text types

$text-types: (
  body: (
    font-size: unit(4),
    line-height: unit(5.5),
    // 1.375
    color: palette(greyscale-level-2),
    font-weight: 400,
  ),
  user-input: (
    font-size: unit(4),
    // 16
    line-height: unit(5),
    // 1.42857142
    color: palette(greyscale-level-1),
    font-weight: 400,
  ),
  heading: (
    font-size: unit(6),
    line-height: unit(8),
    // 1.33333333
    color: palette(greyscale-level-1),
    font-weight: 500,
  ),
  title: (
    font-size: unit(4),
    line-height: unit(5.5),
    // 1.375
    color: palette(greyscale-level-1),
    font-weight: 500,
  ),
  subtitle: (
    font-size: unit(3.5),
    line-height: unit(4.5),
    // 1.28571428
    color: palette(greyscale-level-2),
    font-weight: 400,
  ),
  caption: (
    font-size: unit(3),
    line-height: unit(4),
    // 1.33333333
    color: palette(greyscale-level-3),
    font-weight: 400,
  ),

  // Styles
  primary:
    (
      color: palette(primary-default),
    ),
  amplified: (
    color: palette(greyscale-level-1),
    color-rgb: palette-rgb(greyscale-level-1),
  ),
  muted: (
    color: palette(greyscale-level-3),
  ),
  success: (
    color: palette(success-default),
  ),
  warning: (
    color: palette(warning-default),
  ),
  expose: (
    color: palette(expose-default),
  ),
  inverse: (
    color: palette(greyscale-level-6),
  ),
) !default;

$responsive-text-types: (
  large: (
    heading: (
      mobile: (
        font-size: unit(8),
        line-height: unit(10.5),
        color: palette(greyscale-level-1),
        font-weight: 500,
      ),

      desktop: (
        font-size: unit(12),
        line-height: unit(16),
        color: palette(greyscale-level-1),
        font-weight: 500,
      ),
    ),

    title: (
      mobile: (
        font-size: unit(4.5),
        line-height: unit(7),
        color: palette(greyscale-level-1),
        font-weight: 500,
      ),

      desktop: (
        font-size: unit(6),
        line-height: unit(9),
        color: palette(greyscale-level-1),
        font-weight: 500,
      ),
    ),

    body: (
      mobile: (
        font-size: unit(4.5),
        line-height: unit(7),
        color: palette(greyscale-level-2),
        font-weight: 400,
      ),

      desktop: (
        font-size: unit(6),
        line-height: unit(9),
        color: palette(greyscale-level-2),
        font-weight: 400,
      ),
    ),
  ),

  small: (
    heading: (
      mobile: (
        font-size: unit(6),
        line-height: unit(8),
        color: palette(greyscale-level-1),
        font-weight: 500,
      ),

      desktop: (
        font-size: unit(8),
        line-height: unit(10.5),
        color: palette(greyscale-level-1),
        font-weight: 500,
      ),
    ),

    title: (
      mobile: (
        font-size: unit(4),
        line-height: unit(6.5),
        color: palette(greyscale-level-1),
        font-weight: 500,
      ),

      desktop: (
        font-size: unit(4.5),
        line-height: unit(7),
        color: palette(greyscale-level-1),
        font-weight: 500,
      ),
    ),

    body: (
      mobile: (
        font-size: unit(4),
        line-height: unit(6.5),
        color: palette(greyscale-level-2),
        font-weight: 400,
      ),

      desktop: (
        font-size: unit(4.5),
        line-height: unit(7),
        color: palette(greyscale-level-2),
        font-weight: 400,
      ),
    ),
  ),
);
