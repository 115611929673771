.photo-tips__indicator-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.photo-tips__indicator {
  display: flex;
  padding: $photo-tips-indicator-padding;
}

.photo-tips__action {
  width: $photo-tips-action-dimension;
  height: $photo-tips-action-dimension;
  background-color: $photo-tips-action-color;
  border-radius: unit(10);
}

.photo-tips__action--active {
  background-color: $photo-tips-action-active-color;
}

.photo-tips__banner {
  border-radius: unit(2);
  overflow: hidden;
}
