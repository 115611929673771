.list-promotion {
  background-color: palette(greyscale-level-6);
}

.list-promotion__item-wrapper {
  @include stretch();
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-promotion__item-box {
  position: relative;
  width: $list-promotion-item-box-width;
}

.list-promotion__overlay {
  @include stretch();
}
