.message-menu {
  position: relative;
  margin: auto 0;
}

.message-menu__content {
  position: absolute;
  bottom: $message-menu-content-bottom;
  left: $message-menu-content-position-left;
  z-index: map-get($zindex, dropdown);
}

.message-menu--hidden {
  display: none;
}
