.profile-badges__progress {
  --size: 48px;
  --half-size: calc(var(--size) / 2);
  --icon-size: 32px;
  --stroke-width: 4.5px;
  --progress-radius: calc((var(--size) - var(--stroke-width)) / 2);
  --small-background-radius: calc(var(--icon-size) / 2);
  --circumference: calc(var(--progress-radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);

  position: relative;

  &-bar__background,
  &-bar__filling {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--progress-radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
    position: absolute;
  }

  &-bar__background {
    stroke: palette(greyscale-level-3);
  }

  &-bar__filling {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    stroke: palette(primary-default);
  }

  &-small-background {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--small-background-radius);
    fill: palette(primary-light);
  }

  &-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.35;

    &--active {
      opacity: 1;
    }
  }
}
