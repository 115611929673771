// Navigation with details (count bubbles etc.)
//
//  extends .nav
//

$nav-details-height: 38px;

.nav-detailed {
  & > .nav-item {
    & > .nav-link {
      &:after {
        @include fully-rounded;
        @include content('inline-block', attr(data-details));
        margin: 0 0 0 5px;
        font-weight: 500;
        line-height: initial;
        color: palette(greyscale-level-5);
        text-align: right;
      }
    }
  }
}
