#onetrust-consent-sdk {
  z-index: map-get($zindex, above-header);
  position: relative;

  // Bold font overrides
  #onetrust-banner-sdk,
  #onetrust-pc-sdk {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
    }
  }

  #onetrust-pc-sdk {
    button,
    #ot-pc-title,
    #ot-lst-title h3,
    #ot-category-title,
    #ot-lst-title,
    .ot-cat-header,
    .ot-ven-hdr .ot-ven-name,
    .ot-always-active {
      font-weight: 500;
    }
  }

  #onetrust-banner-sdk {
    .ot-tcf2-vendor-count.ot-text-bold,
    #onetrust-accept-btn-handler,
    #onetrust-reject-all-handler,
    #onetrust-pc-btn-handler {
      font-weight: 500;
    }
  }
}

.body-content #ot-sdk-cookie-policy {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }
}
