.document__uploader-container {
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;
}

.document__uploader-file-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  border-radius: inherit;
  flex: 1;

  width: unit(30);
  height: unit(30);
  text-align: center;

  @include respond-to(tablets-up) {
    width: unit(38);
    height: unit(38);
  }
}

.document-delete-button {
  position: absolute;
  top: unit(2.5);
  right: unit(2.5);
}

.document__uploader-file-divider {
  align-items: center;
  height: unit(20);
  width: unit(15);

  @include respond-to(tablets-up) {
    width: unit(20);
  }

  @include respond-to(phones) {
    width: unit(0.5);
  }
}

.document__uploader-file-margins {
  margin-right: unit(4);
  margin-bottom: unit(4);
}

.hide-on-mobile {
  @media (max-width: unit(80)) {
    display: none;
  }
}
