$page-max-width: unit(264.25);
$howitworks-card-image-height: unit(55.25);

@mixin dac7-page-max-width {
  max-width: $page-max-width;
}

@mixin dac7-break-layout {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

@mixin dac7-section-spacing-large($direction) {
  padding-#{$direction}: unit(12);

  @include respond-to(desktops) {
    padding-#{$direction}: unit(16);
  }
}

@mixin dac7-content-padding($desktops-padding: 0) {
  padding-right: unit(2.5);
  padding-left: unit(2.5);

  @include respond-to(desktops) {
    padding-right: $desktops-padding;
    padding-left: $desktops-padding;
  }
}

@mixin dac7-section-padding($desktops-padding: 0) {
  padding-right: unit(5);
  padding-left: unit(5);

  @include respond-to(desktops) {
    padding-right: $desktops-padding;
    padding-left: $desktops-padding;
  }
}

@mixin dac7-title() {
  color: palette(greyscale-level-1);
  font-weight: 375;
  font-size: unit(8);
  line-height: unit(10.5);
  text-align: left;

  @include respond-to(desktops) {
    font-size: unit(10.5);
    line-height: unit(13);
    text-align: center;
  }
}

@mixin dac7-description {
  color: palette(greyscale-level-2);
  margin: 0 auto;
  font-weight: 375;
  font-size: unit(5);
  line-height: unit(7);
  max-width: unit(217.5);
  text-align: left;

  @include respond-to(desktops) {
    font-size: unit(6);
    line-height: unit(9);
    text-align: center;
  }
}

@mixin dac7-cta-text() {
  @include dac7-content-padding(unit(2.5));

  font-size: unit(4.5);
  line-height: unit(5.5);
}

.dac7__wrapper {
  padding: unit(0) unit(2.5);

  @include respond-to(desktops) {
    padding: unit(0) unit(1);
  }
}

.dac7__introduction-title {
  @include dac7-title;

  padding-top: unit(5);
  font-weight: 500;

  @include respond-to(desktops) {
    padding-top: unit(11);
  }
}

.dac7__introduction-description {
  @include dac7-description;

  padding-bottom: unit(12);
  padding-top: unit(8);

  @include respond-to(desktops) {
    padding-top: unit(12);
  }
}

.dac7__taxrules {
  @include dac7-break-layout;
  background-color: palette-light(primary-default);
  color: palette(greyscale-level-6);
}

.dac7__taxrules-inner {
  @include dac7-section-spacing-large(top);
  @include dac7-section-spacing-large(bottom);

  margin: unit(0) auto;
  max-width: $page-max-width + unit(10);
  padding-left: unit(5);
  padding-right: unit(5);
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;

  @include respond-to(desktops) {
    margin: unit(0) auto;
    padding-left: unit(5);
    padding-right: unit(5);
    flex-direction: row;
    justify-content: space-between;
  }
}

.dac7__taxrules-image {
  width: 100%;
  max-width: unit(100);
  padding-top: unit(8);
  margin: 0 auto;

  @include respond-to(desktops) {
    margin: 0;
    padding-top: unit(0);
  }

  & > div {
    border-radius: unit(2.5);

    @include respond-to(desktops) {
      border-radius: unit(5);
    }
  }
}

.dac7__taxrules-title {
  @include dac7-title();

  font-weight: 500;
  color: palette(greyscale-level-6);
  text-align: left;

  @include respond-to(desktops) {
    text-align: left;
    max-width: unit(122.5);
    padding-right: unit(4);
  }
}

.dac7__taxrules-description {
  @include dac7-description;

  color: palette(greyscale-level-6);
  padding-top: unit(8);
  padding-bottom: unit(8);
  margin: 0;
  text-align: left;

  @include respond-to(desktops) {
    max-width: unit(122.5);
    text-align: left;
    padding-top: unit(6);
    padding-bottom: unit(12);
    font-size: unit(4.5);
    line-height: unit(7);
    padding-right: unit(4);
  }
}

.dac7__taxrules-cta {
  margin: unit(0) auto;
  width: 100%;

  @include respond-to(desktops) {
    width: fit-content;
    min-width: unit(52.5);
    margin: unit(0);
  }
}

.dac7__taxrules-cta-text {
  @include dac7-cta-text;
}

.dac7__apply {
  @include dac7-page-max-width;

  margin: unit(20) 0 unit(12) 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: unit(23.5);

  @include respond-to(desktops) {
    flex-direction: row;
    margin: unit(24) auto;
  }
}

.dac7__apply-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include respond-to(desktops) {
    display: flex;
  }
}

.dac7__apply-title {
  @include dac7-title;

  width: fit-content;
  font-weight: 500;
}

.dac7__apply-description {
  @include dac7-description;

  font-size: unit(4.5);
  line-height: unit(7);
  width: fit-content;
  margin: unit(8) 0 0 0;

  @include respond-to(desktops) {
    font-size: unit(4.5);
    line-height: unit(7);
    text-align: left;
    margin: unit(6) 0 0 0;
  }

  b {
    font-weight: 500;
  }
}

.dac7__apply-image {
  width: 100%;
  max-width: unit(125);
  padding-top: unit(8);
  margin: 0 auto;

  @include respond-to(desktops) {
    margin: 0;
    padding-top: unit(0);
  }

  & > div {
    border-radius: unit(2.5);

    @include respond-to(desktops) {
      border-radius: unit(5);
    }
  }
}

.dac7__howitworks {
  @include dac7-break-layout;
  background: palette-light(ca10);
  padding-top: unit(12);

  @include respond-to(desktops) {
    padding-top: unit(0);
    position: relative;
    margin-top: unit(49) + $howitworks-card-image-height;
  }
}

.dac7__howitworks-steps {
  @include respond-to(desktops) {
    top: (unit(22.5) + $howitworks-card-image-height) * -1;
    margin-bottom: (unit(22.5) + $howitworks-card-image-height) * -1;
    position: relative;
    padding-bottom: unit(24);
  }
}

.dac7__howitworks-inner {
  @include dac7-section-padding(unit(5));

  width: 100%;
  max-width: $page-max-width + unit(10);
  margin: unit(0) auto;
  position: relative;
  box-sizing: border-box;
}

.dac7__howitworks-title {
  color: palette(greyscale-level-1);
  font-weight: 500;
  font-size: unit(6);
  line-height: unit(9);
  margin-bottom: unit(8);

  @include respond-to(desktops) {
    font-size: unit(8);
    line-height: unit(10.5);
    margin-bottom: unit(12);
  }
}

.dac7__howitworks-cards {
  @include respond-to(desktops) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}
.dac7__howitworks-card {
  display: flex;
  flex: 1 0 0;
  flex-flow: column;
  align-items: center;

  @include respond-to(desktops) {
    &:first-of-type {
      align-items: start;
    }
    &:last-of-type {
      align-items: end;
    }
  }
}
.dac7__howitworks-card-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  width: unit(82.75);
  margin-bottom: unit(8);
  background: palette(greyscale-level-6);
  border-radius: unit(5);

  @include respond-to(desktops) {
    border-radius: 0 0 unit(5) unit(5);
    height: 100%;
    align-items: stretch;
    width: calc(100% - unit(16) / 3);
    margin-bottom: unit(0);
  }
}

.dac7__howitworks-card-image {
  min-height: $howitworks-card-image-height;
  height: $howitworks-card-image-height;
  width: 100%;
}

.dac7__howitworks-card-content {
  padding: unit(5) unit(8) unit(8) unit(8);

  @include respond-to(desktops) {
    padding-top: unit(3);
    padding-bottom: unit(8);
  }
}

.dac7__howitworks-card-title {
  color: palette(greyscale-level-1);
  font-weight: 500;
  font-size: unit(5);
  line-height: unit(7);
  margin-bottom: unit(4);
  font-size: unit(5);
  line-height: unit(7);
  margin-bottom: unit(8);

  @include respond-to(desktops) {
    font-size: unit(5.5);
    line-height: unit(7.5);
    margin-bottom: unit(3);
  }
}

.dac7__howitworks-card-description {
  color: palette(greyscale-level-2);
  font-weight: 375;
  font-size: unit(4);
  line-height: unit(6.5);

  b {
    font-weight: 500;
  }
}

.dac7__howitworks-closing {
  @include dac7-section-padding(unit(2.5));
  @include dac7-section-spacing-large(bottom);

  max-width: $page-max-width + unit(10);
  box-sizing: border-box;
  padding-top: unit(8);
  display: flex;
  flex-direction: column;

  @include respond-to(desktops) {
    justify-content: space-between;
    margin: 0 auto;
    flex-direction: row;
    padding-bottom: unit(24);
    padding-top: 0;
  }
}

.dac7__howitworks-closing.no-padding-top {
  padding-top: 0;
}

.dac7__howitworks-closing-text-container {
  width: 100%;
  max-width: unit(165);
}

.dac7__howitworks-closing-title {
  color: palette(greyscale-level-1);
  font-size: unit(5);
  line-height: unit(7);
  font-weight: 500;
  margin-bottom: unit(0);

  @include respond-to(desktops) {
    font-size: unit(7.75);
    line-height: unit(10.25);
    margin-bottom: unit(6.5);
  }
}

.dac7__howitworks-closing-image {
  width: 100%;
  max-width: unit(125);
  margin: 0 auto;

  @include respond-to(desktops) {
    margin: 0;
    width: unit(66.25);
    height: unit(37.25);
  }
}

.dac7__howitworks-closing-description {
  color: palette(greyscale-level-2);
  font-size: unit(4);
  line-height: unit(6.5);
  margin-top: unit(2);

  @include respond-to(desktops) {
    font-size: unit(4.25);
    line-height: unit(6.5);
    margin-top: unit(8);
  }

  b {
    font-weight: 500;
  }
}

.dac7__checkstatus {
  @include dac7-break-layout;
  background-color: palette-light(primary-default);
  color: palette(greyscale-level-6);
}

.dac7__checkstatus-inner {
  margin: unit(0) auto;
  max-width: $page-max-width + unit(10);
  padding-left: unit(5);
  padding-right: unit(5);
  padding-top: unit(12);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;

  @include respond-to(desktops) {
    padding-top: unit(12);
    margin: unit(0) auto;
    padding-left: unit(5);
    padding-right: unit(5);
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.dac7__checkstatus-image {
  margin: 0;
  height: unit(83.5);
  width: unit(125);
  align-self: center;
  margin-top: unit(8);

  @include respond-to(desktops) {
    margin-top: unit(0);
    margin-left: unit(16);
    align-self: flex-end;
    height: unit(83.5);
    width: unit(125);
  }
}

.dac7__checkstatus-title {
  @include dac7-title();

  font-weight: 500;
  color: palette(greyscale-level-6);
  text-align: left;
  margin-bottom: unit(8);

  @include respond-to(desktops) {
    text-align: left;
    max-width: unit(122.5);
    padding-top: unit(8);
  }
}

.dac7__checkstatus-cta {
  margin: unit(0) auto;
  width: 100%;

  @include respond-to(desktops) {
    width: fit-content;
    min-width: unit(52.5);
    margin: unit(0);
  }
}

.dac7__checkstatus-cta-text {
  font-weight: 500;
  font-size: unit(4);
  line-height: unit(6);
}

.dac7__links {
  @include dac7-page-max-width;

  margin: 0 auto;
}

.dac7__links-title {
  @include dac7-section-spacing-large(top);
  color: palette(greyscale-level-1);
  font-size: unit(6);
  line-height: unit(8);
  margin-bottom: unit(3);
  text-align: left;
  font-weight: 500;

  @include respond-to(tablets-up) {
    margin-bottom: 0;
    padding-bottom: unit(12);
    text-align: left;
    font-size: unit(8);
    line-height: unit(10.5);
  }
}

.dac7__links-wrapper {
  display: block;
  justify-content: flex-start;
  gap: unit(0) unit(11.75);

  @include respond-to(tablets-up) {
    display: flex;
  }
}

.dac7__links-item {
  font-weight: 400;
  font-size: unit(4);
  line-height: unit(6.5);
}

.dac7__links-group {
  min-width: unit(0);
  padding-top: unit(5);

  @include respond-to(tablets-up) {
    width: unit(85.5);
    padding-top: unit(0);
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    padding: unit(0) unit(0) unit(3) unit(0);
    text-indent: 0;
    list-style-type: none;
  }

  .dac7__links-group-heading {
    font-weight: 500;
    font-size: unit(5.5);
    line-height: unit(7.5);
    margin-bottom: unit(1);
    font-size: unit(5.5);
    line-height: unit(7.5);
    font-weight: 500;
    color: palette(shadow);

    @include respond-to(tablets-up) {
      font-weight: 500;
      font-size: unit(6);
      line-height: unit(8);
      margin-bottom: unit(4);
    }
  }
}
