.hero-block {
  position: relative;
  background-color: $hero-block-default-bg-color;

  @include respond-to(tablets-up) {
    display: flex;
    box-sizing: border-box;
    height: $hero-block-image-max-height;
    min-height: $hero-block-box-height-tablet;
    padding-top: $hero-block-padding;
    padding-bottom: $hero-block-padding;
    justify-content: center;
    flex-direction: column;
  }
}

.hero-block__background {
  position: relative;
  height: 100%;

  @include respond-to(phones) {
    img {
      max-height: $hero-block-image-max-height;
    }
  }

  @include respond-to(tablets-up) {
    @include stretch(0, 0, 0, 0);
  }
}

.hero-block__overlay {
  @include stretch(0, 0, 0, 0);

  background-image: url('../../../next/public/assets/hero-block/tear.svg');
  // Note: 1px offset is due to inconsistent calculation of background position
  background-position: bottom -1px right -1px;
  background-repeat: no-repeat;
  background-size: contain;

  @include respond-to(tablets-up) {
    margin-left: 50%;
  }
}

.hero-block__wrapper {
  display: flex;
  box-sizing: border-box;
  max-width: $hero-block-wrapper-max-width;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
