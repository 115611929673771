.main-footer__content {
  background-color: $main-footer-bg;
  padding-bottom: $main-footer-bottom-padding;
  padding-top: $main-footer-top-padding;
}

.main-footer__privacy-section {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.main-footer__privacy-section-item {
  margin: 0px;
  padding: $main-footer-links-padding;
}

.main-footer__privacy-section-link,
.main-footer__links-section-link {
  @include font-size($font-size-medium);
  line-height: $main-footer-link-line-height;
  color: $main-footer-link-color;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border: 0;

  &:hover {
    text-decoration: underline;

    &::after {
      background: none;
      border: 0;
    }
  }
}

.main-footer__social-section {
  display: flex;
  justify-content: space-between;
  padding: $main-social-padding;

  @include respond-to(phones) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.main-footer__social-section-media,
.main-footer__social-section-app-store {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.main-footer__social-media-item {
  margin: $main-social-media-links;
}

.main-footer__social-app-store-item {
  margin: $main-app-store-links;

  @include respond-to(phones) {
    margin: $main-app-store-links-phone;
  }
}

.main-footer__links-section {
  display: flex;
  justify-content: space-between;
  list-style-type: none;

  @include respond-to(phones) {
    flex-direction: column;
  }
}

.main-footer__links-section-item {
  margin: 0px;
  padding: $main-footer-links-padding;
}

.main-footer__links-section-row {
  margin: $main-links-link-item-margin;
}

.main-footer__links-section-label {
  @include font-size($font-size-large);
  line-height: $main-footer-link-line-height;
}

.main-footer__links-section-group {
  list-style-type: none;
}
