.image-carousel {
  @include stretch(0, 0, 0, 0);
  position: fixed;
  z-index: $image-carousel-z-index;
  display: flex;
  background-color: $image-carousel-background-color;
  align-items: center;
  justify-content: center;
}

.image-carousel__image-wrapper {
  position: relative;
  display: flex;
  max-width: $image-carousel-image-wrapper-width;
  max-height: $image-carousel-image-wrapper-height;
  background-color: $image-carousel-image-wrapper-background-color;
}

.image-carousel__image {
  display: none;
  max-width: $image-carousel-image-max-width;
  max-height: $image-carousel-image-max-height;
  object-fit: contain;
}

.image-carousel__image--shown {
  display: unset;
}

.image-carousel__button {
  position: absolute;
  display: flex;
  box-sizing: border-box;
  height: $image-carousel-button-height;
  padding: $image-carousel-button-padding;
  cursor: pointer;
  opacity: $image-carousel-button-opacity;
  align-items: center;

  &:hover {
    opacity: $image-carousel-button-hover-opacity;
    transition: $image-carousel-button-hover-transition;
  }
}

.image-carousel__button--right {
  right: 0;
}

.image-carousel__button--close {
  top: 0;
  right: 0;
  height: unset;
}

.image-carousel__icon-wrapper {
  display: flex;
  padding: $image-carousel-icon-wrapper-padding;
  background-color: $image-carousel-icon-wrapper-background-color;
  border-radius: $image-carousel-icon-wrapper-border-radius;
}
