.c-input {
  @include font-smoothing;
  display: block;
  padding: $input-padding;
  font-size: 0;
  background: $input-background-color;
  align-items: center;

  .c-input__value {
    @include type($input-value-text-type);
    width: 100%;
    padding: 0 0 $input-value-padding;
    font-family: inherit;
    background-color: transparent;
    border: 0;
    border-bottom: $input-value-border;
    border-radius: 0;
    resize: none;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &[type='number'] {
      -moz-appearance: textfield;
    }

    // Placeholder pseudos need to be separated
    // in order to work
    &::-webkit-input-placeholder {
      @include type($input-value-placeholder-theme);
      // Using RGBA instead of opacity to work around MS Edge bug:
      // https://connect.microsoft.com/IE/feedback/details/1627778/microsoft-edge-placeholder-bug
      color: rgba(
        map-get($input-value-placeholder-theme, color-rgb),
        $input-value-placeholder-opacity
      );
    }

    &::-moz-placeholder {
      @include type($input-value-placeholder-theme);
      // Workaround, see above
      color: rgba(
        map-get($input-value-placeholder-theme, color-rgb),
        $input-value-placeholder-opacity
      );
    }

    &:-ms-input-placeholder {
      @include type($input-value-placeholder-theme);
      // Workaround, see above
      color: rgba(
        map-get($input-value-placeholder-theme, color-rgb),
        $input-value-placeholder-opacity
      );
    }

    &::-webkit-clear-button {
      display: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }

    &::-webkit-inner-spin-button {
      display: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }

    &:not(:focus)::-webkit-calendar-picker-indicator {
      opacity: 1;
    }

    &::-webkit-calendar-picker-indicator {
      @include size($input-date-arrow-size);
      padding: 0;
      color: transparent;
      background: none;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4gIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgIDxyZWN0IHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIvPiAgICA8cG9seWdvbiBmaWxsPSIjQkJCQkJCIiBwb2ludHM9IjEgNCA2IDkgMTEgNCIvPiAgPC9nPjwvc3ZnPg==);
      background-position: center;
      background-repeat: no-repeat;
    }

    &:invalid::-webkit-datetime-edit {
      @include type($input-value-placeholder-theme);
      opacity: $input-value-placeholder-opacity;
    }

    &:focus {
      border-color: $input-focused-value-border-color;
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: $input-disabled-opacity-unit;
    }
  }

  .c-input__value--with-suffix {
    @include ellipsis;
    padding-right: $input-value-suffix-padding;
  }
}

.c-input__title {
  @include type($input-title-text-type);
  padding-bottom: $input-title-padding-bottom;
  align-self: flex-start;
}

.c-input__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  font-size: 0;
}

.c-input__icon {
  position: absolute;
  right: 0;
  bottom: $input-value-padding * 2;
  z-index: map-get($zindex, bump);
  line-height: 0;
}

.c-input__note {
  @include type($input-note-text-type);
  box-sizing: border-box;
  width: 100%;
  padding-top: $input-note-padding-top;
}

.c-input__wrapper--disabled {
  width: 100%;
  cursor: not-allowed;

  input {
    pointer-events: none;
  }
}

// Styles

.c-input--narrow {
  padding: $input-narrow-padding;
}

.c-input--tight {
  padding: $input-tight-padding;
}

.c-input--wide {
  @include respond-to(tablets-up) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: $input-wide-padding;
  }

  .c-input__title {
    @include respond-to(tablets-up) {
      @include type($input-wide-title-text-type);
      width: 50%;
      padding: 0;
    }
  }

  .c-input__content {
    @include respond-to(tablets-up) {
      width: 50%;

      &:first-child {
        width: 100%;
      }
    }
  }

  .c-input__note {
    @include respond-to(tablets-up) {
      width: 50%;
    }
  }
}

.c-input--transparent {
  background: transparent;
}

// Themes

.c-input--primary {
  background: $input-primary-background-color;

  .c-input__title {
    @include type($input-primary-title-theme);
  }

  .c-input__value {
    @include type($input-primary-value-theme);
    border-color: $input-inverse-value-border-color;

    &::-webkit-input-placeholder {
      @include type($input-primary-placeholder-theme);
    }

    &::-webkit-calendar-picker-indicator {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4gIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgb3BhY2l0eT0iLjgiPiAgICA8cmVjdCB3aWR0aD0iMTIiIGhlaWdodD0iMTIiLz4gICAgPHBvbHlnb24gZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSIxIDQgNiA5IDExIDQiLz4gIDwvZz48L3N2Zz4=);
    }

    &:focus {
      border-color: $input-inverse-focused-value-border-color;
    }
  }
}
