$headings: 'h1, h2, h3, h4, h5, h6';

#{$headings},
p,
ul,
ol,
table,
.heading {
  margin: $half-gutter 0 0;
}

// FIXME. prevent hardcoded margin-top for ULs
#{$headings},
p,
ol,
table {
  &:first-child {
    margin-top: 0 !important;
  }
}

#{$headings},
.heading {
  @include font-smoothing;
  padding: 0;
  font-weight: normal;
  line-height: $line-height-medium;
  color: inherit;
  word-break: normal;
}

h1,
.heading {
  @include font-size($font-size-big);
}

h2 {
  @include font-size($font-size-heading);
}

h3 {
  @include font-size($font-size-hero);
}

h4 {
  @include font-size($font-size-medium);
  font-weight: bold;
  line-height: $line-height-large;
}

h5 {
  @include font-size($font-size-default);

  font-weight: bold;
  line-height: $line-height-large;
}

h6 {
  @include font-size($font-size-medium);
  font-weight: bold;
}

h1 + .subtitle {
  margin-top: $half-gutter;
}

p,
ol {
  color: inherit;
}

ul {
  color: inherit;
}

sup {
  vertical-align: text-top;
  display: inline-block;
  font-size: smaller;
}

strong {
  @include font-smoothing;
}

// Other
//
// .inverse           - Large font heading
// .small-font        - Text in small font
// .strike-through    - Text with strike through style
// .uppercase         - Text in uppercase
// .center            - Centered text
// .u-no-wrap         - Text without wrapping

.inverse {
  color: palette(primary-default) !important;
}

.u-color-green {
  color: palette(success-default);
}

.center,
.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right !important;
}

.u-no-wrap {
  white-space: nowrap !important;
}
