.app__row {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include respond-to(desktops) {
    flex-direction: row;
  }
}

.app__cell {
  flex: 1 1 auto;
  width: 100%;
}

.app__photo {
  box-sizing: border-box;
  width: 100%;
  padding: 0 $gutter;
  position: relative;

  @include respond-to(desktops) {
    width: auto;
    height: $app-photo-height;
  }
}
