.horizontally-scrollable-items__item {
  position: relative;
  box-sizing: border-box;
  width: fractional-width(2.5);

  @include respond-to(tablets) {
    width: fractional-width(3.5);
  }

  @include respond-to(desktops) {
    width: fractional-width(4.5);
  }

  @include respond-to(wide) {
    width: fractional-width(6);
  }
}

.horizontally-scrollable-items__item-content {
  display: flex;
  justify-content: center;
}

.horizontally-scrollable-items__cta-content {
  display: flex;
  @include size(100%);
  background: palette(greyscale-level-6);
}
