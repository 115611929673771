.order-summary__single-item-presentation {
  display: flex;
  flex-direction: row;
}

.order-summary__texts-with-amounts-container {
  display: flex;
  flex-direction: column;
}

.order-summary__amounts-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: flex-end;
}

.order-summary__amounts {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.order-summary__texts-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}

.order-summary__image-wrapper {
  padding: 12px;

  > div {
    width: 72px;
    height: 72px;
  }
}

.order-summary-v2__icon-wrapper {
  display: flex;
  height: 22px;
}

.order-summary__text-wrapper {
  display: flex;
  overflow: hidden;

  :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

.checkout-order-summary-v2-escrow-conversion-note {
  display: flex;
  align-items: center;
  height: 16px;
}
