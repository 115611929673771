.nps-survey {
  right: 0;
  left: 0;
  width: 400px;
  max-width: 100%;
  margin: auto;
  text-align: center;
}

.nps-survey__ratings {
  display: flex;
  justify-content: space-between;
}
