.how-it-works__header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  background: palette(primary-light) 50% 50% / cover no-repeat;

  img {
    height: 100%;
  }
}

.how-it-works__header-title {
  @include type(map-get(map-get(map-get($responsive-text-types, large), heading), mobile));

  @include respond-to(tablets-up) {
    @include type(map-get(map-get(map-get($responsive-text-types, large), heading), desktop));
  }

  u {
    position: relative;
    text-decoration: none;
    white-space: nowrap;

    &:before {
      position: absolute;
      top: unit(8);
      right: unit(-1);
      bottom: unit(-8);
      left: unit(-1);
      background: $how-it-works-header-title-stroke-background;
      background-repeat: no-repeat;
      background-size: 100% unit(3);
      content: '';
      transform: rotate(-1deg);

      @include respond-to(tablets-up) {
        top: unit(12);
        background-size: 100% unit(3);
      }
    }
  }
}

.how-it-works__header-body {
  @include type(map-get(map-get(map-get($responsive-text-types, large), body), mobile));

  @include respond-to(tablets-up) {
    @include type(map-get(map-get(map-get($responsive-text-types, large), body), desktop));
  }
}

.how-it-works__header-image-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  text-align: right;

  @include respond-to(tablets) {
    left: 50%;
  }

  @include respond-to(desktops) {
    left: 30%;
  }
}

.how-it-works__get-started {
  background: palette(primary-default) 50% 50% / cover no-repeat;
}

.how-it-works__get-started-title {
  @include type(map-get(map-get(map-get($responsive-text-types, large), heading), mobile));

  @include respond-to(desktops) {
    @include type(map-get(map-get(map-get($responsive-text-types, large), heading), desktop));

    color: inherit;
  }

  color: inherit;
}

.how-it-works__link {
  font-size: unit(4);
}
