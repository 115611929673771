// Navigation in dropdowns
//
// extends .nav
//

.nav-dropdown {
  .nav-link {
    @include font-size($font-size-medium);
    @include font-smoothing;
    border-radius: $dropdown-nav-item-border-radius-size;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &--secondary {
      color: palette(greyscale-level-3);
    }
  }

  &.nav-stacked {
    .nav-link {
      box-sizing: border-box;
      width: 100%;
      padding: 5px 10px;

      text-align: left;

      &:hover {
        background-color: $dropdown-nav-item-hover-color;
      }
    }
  }

  &.nav-detailed {
    .nav-item {
      .nav-link {
        position: relative;

        &:after {
          @include font-size($font-size-small);
          position: inherit;
          top: -3px; // Center title and counter with icon
          display: block;
          margin: 0 !important;
          text-align: left;
          border: 0;
        }

        &:hover {
          background-color: $dropdown-nav-item-hover-color;

          &:after {
            color: palette(greyscale-level-3);
            background-color: transparent;
            border: none;
          }
        }
      }

      &.active {
        .nav-link {
          &:after {
            color: palette(greyscale-level-3);
          }
        }
      }
    }
  }
}
