.color-select__value {
  @include size($color-select-icon-size);
  @include fully-rounded;
}

// Modifiers for each color
@each $color, $value in $color-select-colors-list {
  .color-select__value--#{$color} {
    background: $value;
    background-size: contain;
  }
}

// Border for light colors so that they stand out
.color-select__value--white,
.color-select__value--cream {
  box-sizing: border-box;
  border: 1px solid $color-select-border-color;
}
