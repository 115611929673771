.item-upload__field {
  @include respond-to(tablets-up) {
    padding-left: 50% !important;
  }
}

.item-upload__similar-items-grid {
  display: flex;
  flex-wrap: wrap;
}

.item-upload__similar-items-grid-item {
  box-sizing: border-box;
  width: percentage(1 * 0.5);
  position: relative;
  padding: 0 unit(1) unit(2);
}

.item-upload__isbn-modal-image {
  max-width: unit(50);
}

.item-upload__feedback-notification {
  min-width: calc(100vw - #{$profile-item-upload-feedback-notification-phones-min-width-spacer});

  @include respond-to(tablets-up) {
    min-width: $profile-item-upload-feedback-notification-tablets-up-min-width;
  }
}

.form-actions {
  position: relative;
  background-size: contain;
  padding: 0 0 $gutter;
  margin-top: $gutter;
  line-height: 36px;

  @include respond-to(phones) {
    text-align: center;
  }
}
