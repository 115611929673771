.aspect-ratio {
  position: relative;
}

.aspect-ratio__ratio {
  position: relative;
  width: 100%;
  height: 0;
}

.aspect-ratio__content {
  @include stretch;
}
