$modal-in-app-message-cover-min-height: unit(160);
$modal-in-app-message-image-bottom-margin: unit(4);
$modal-in-app-message-no-image-bottom-margin: unit(13);

.modal-in-app-message--cover {
  @include respond-to(tablets-up) {
    min-height: $modal-in-app-message-cover-min-height;
  }
}

.modal-in-app-message__splash-image {
  max-height: 50%;
  margin-bottom: $modal-in-app-message-image-bottom-margin;
}

.modal-in-app-message__no-image {
  margin-bottom: $modal-in-app-message-no-image-bottom-margin;
}
