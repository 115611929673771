.pickup-point-dialog_overwrite {
  border-radius: 0;
}

.pickup-point-modal {
  height: 100%;
}

.pickup-point-modal__content {
  display: flex;
  min-height: 0;
  flex: 1;
}

.pickup-point-modal__scrollable-list {
  @include ios-smooth-scroll;

  width: fit-content;
  max-width: $pickup-point-map-list-container-max-width;
  min-width: $pickup-point-map-list-container-min-width;
}

.pickup-point-modal__scrollable-list__content {
  overflow: auto;
  flex: 1;
}

.pickup-point-modal__map-container {
  position: relative;
  width: 100%;
}

.pickup-point-modal--phones__container {
  text-align: center;
  justify-content: center;
  flex: 1;
}

.pickup-point-modal,
.pickup-point-modal__scrollable-list,
.pickup-point-modal__map-container,
.pickup-point-modal--phones__container {
  display: flex;
  flex-direction: column;
}
