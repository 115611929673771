.drop-off-point-map-modal {
  position: relative;
  height: 100%;
}

.drop-off-point-map-modal__content {
  display: flex;
  min-height: 0;
  flex: 1;
}

.drop-off-point-map-modal--phones__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.drop-off-point-map-modal {
  display: flex;
  flex-direction: column;
}

.drop-off-point-map-details {
  position: absolute;
  bottom: 0;
  width: 100%;
}
