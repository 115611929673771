$influencer-color-1: #edffe3;
$influencer-color-2: #1a4552;
$influencer-color-3: #000000;
$influencer-color-4: #ece2ce;
$new-visual-button-border: unit(2);
$page-content-width: unit(329);
$vchoney-semibold-fontfamily: VCHoneySemiBoldDeck, 'Helvetica Neue', 'Helvetica-Neue', 'Arial',
  sans-serif;

@mixin full-width-section() {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

@mixin content-container() {
  box-sizing: border-box;
  width: 100%;
  max-width: $page-content-width;
  padding-right: unit(5);
  padding-left: unit(5);
  margin-right: auto;
  margin-left: auto;
}

.influencer-v2-landing-page {
  @include content-container;

  position: relative;
  display: block;
}

.influencer-v2_introduction-section {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  overflow-x: hidden;
}

.influencer-v2__introduction-title {
  font-weight: 500;
  font-size: unit(10);
  line-height: unit(11);
  color: palette(greyscale-level-1);
  margin: 0 auto;
  max-width: unit(83.75);
  padding-top: unit(4);
  text-align: center;
  font-family: $vchoney-semibold-fontfamily;

  @include respond-to(desktops) {
    padding-top: unit(11);
    max-width: 50%;
    font-size: unit(19);
    line-height: unit(20.5);
  }
}

.influencer-v2__introduction-video-iframe {
  border: 0;
  aspect-ratio: 1.75;
  width: 100%;
  max-width: unit(265);
  border-radius: unit(4);
  margin: 0 auto;
}

.influencer-v2__introduction-content {
  width: 100vw;
  margin: unit(7.5) auto unit(23.5);
  padding: unit(0) unit(5);
  box-sizing: border-box;
  text-align: center;

  @include respond-to(desktops) {
    margin-top: unit(13.25);
    margin-bottom: unit(24.75);
    padding: 0;
  }
}

.influencer-v2__introduction-description {
  color: palette(greyscale-level-1);
  margin: 0 auto;
  font-weight: 375;
  font-size: unit(3.5);
  line-height: unit(5);
  text-align: center;
  margin-top: unit(8);
  margin-bottom: unit(10.5);

  @include respond-to(desktops) {
    max-width: unit(210);
    font-size: unit(5);
    line-height: unit(7);
    margin-top: unit(17);
    margin-bottom: unit(13.75);
  }
}

.influencer-v2__introduction-cta {
  margin: 0 auto;
  width: fit-content;

  .web_ui__Button__button {
    border-radius: $new-visual-button-border;

    @include respond-to(desktops) {
      padding-left: unit(7);
      padding-right: unit(7);
    }
  }

  .web_ui__Button__filled {
    background-color: $influencer-color-2;
  }

  .web_ui__Button__default {
    font-size: unit(3.5);

    @include respond-to(wide) {
      font-size: unit(4);
      height: unit(12.5);
    }
  }
}

.influencer-v2__introduction-image {
  width: 100%;
  max-width: unit(103);
  height: unit(102.5);
  position: relative;
  margin: 0 auto;

  @include respond-to(desktops) {
    height: unit(148);
    max-width: 100%;
  }

  .web_ui__Image__content {
    border-radius: unit(4);
  }
}

.influencer-v2__lifestyle-section {
  position: relative;
  overflow-x: hidden;

  @include full-width-section;

  &::after {
    content: '';
    width: 100vw;
    height: 50%;
    position: relative;
    left: calc(-50vw + 50%);
    display: block;
    position: absolute;
    background: $influencer-color-4;
    bottom: 0;
    z-index: 1;
  }
}

.influencer-v2__lifestyle-section-inner {
  margin: 0 auto;
  width: 100%;
  padding-bottom: unit(29);

  @include respond-to(wide) {
    width: fit-content;
    display: flex;
    padding-bottom: unit(42.25);
  }
}

.influencer-v2__lifestyle-title {
  font-family: $vchoney-semibold-fontfamily;
  color: $influencer-color-3;
  font-size: unit(8);
  line-height: unit(9);
  text-align: left;
  padding: unit(0) unit(5) unit(10.75);
  width: unit(75);
  min-height: unit(22.75);

  @include respond-to(tablets) {
    width: 100%;
    min-height: 0;
  }

  @include respond-to(wide) {
    font-size: unit(12);
    line-height: unit(13);
    width: unit(73.25);
    max-width: unit(175);
    padding: unit(13) unit(36.75) unit(0) unit(0);
    min-height: unit(45.25);
  }
}

.influencer-v2__lifestyle-content {
  margin-left: 0;

  @include respond-to(wide) {
    margin-left: 5.5vw;
  }
}

.influencer-v2__lifestyle-cards-wrapper {
  width: fit-content;
  display: block;
  gap: unit(2);
  margin-bottom: unit(0);
  padding: unit(0) unit(5);
  position: relative;
  z-index: 2;

  @include respond-to(wide) {
    overflow: hidden;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: unit(0);
    padding: 0;
  }
}

.influencer-v2__lifestyle-card {
  width: unit(68.7);
  height: fit-content;

  @include respond-to(wide) {
    width: unit(100.5);
  }
}

.influencer-v2__lifestyle-card-image {
  overflow: hidden;
  width: unit(68.75);
  height: unit(84.5);
  border-radius: unit(3.75);

  @include respond-to(wide) {
    width: unit(100.5);
    height: unit(123.75);
    border-radius: unit(5);
  }
}

.influencer-v2__lifestyle-card-title {
  color: $influencer-color-3;
  font-weight: 580;
  font-size: unit(4);
  line-height: unit(5);
  margin: 0;
  padding-top: unit(4);
  padding-bottom: unit(5);

  @include respond-to(wide) {
    font-size: unit(5);
    line-height: unit(6.5);
    padding-top: unit(5.25);
    padding-bottom: unit(5.5);
  }
}

.influencer-v2__lifestyle-card-description {
  color: $influencer-color-3;
  font-weight: 375;
  font-size: unit(3.5);
  line-height: unit(5);
  margin: 0;
  max-width: unit(61.5);

  @include respond-to(wide) {
    max-width: unit(93.25);
    min-height: unit(25.75);
    font-size: unit(4);
    line-height: unit(5.5);
  }
}

.influencer-v2__lifestyle-background {
  background: $influencer-color-4;
  display: block;
  width: 100%;
  height: 42%;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.influencer-v2__slider-wrapper-item {
  padding-right: unit(5.25);

  @include respond-to(wide) {
    padding-right: unit(8.75);
  }
}

.influencer-v2__slider-container-lifestyle {
  width: 100%;
  position: relative;
  display: grid;
  grid-auto-flow: column;

  @include respond-to(desktops) {
    padding-left: unit(0);
  }
}

.influencer-v2__slider-container-lifestyle::-webkit-scrollbar {
  display: none;
}

.influencer-v2__lifestyle-slider-buttons-container {
  display: none;
  position: relative;
  z-index: 2;

  @include respond-to(wide) {
    padding-top: unit(6);
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
  }
}

.influencer-v2__slider-wrapper-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: unit(7.5);
  height: unit(7.5);
  border-radius: 50%;
  border: solid unit(0.375) $influencer-color-3;
  margin-right: unit(2);

  .web_ui__Icon__greyscale-level-1 svg {
    fill: $influencer-color-3;
    color: $influencer-color-3;
  }
}

.influencer-v2__slider-wrapper-button.disabled {
  border: solid unit(0.375) palette(greyscale-level-5);
}

.influencer-v2__rotate-180 {
  transform: rotate(180deg);
}

.influencer-v2__closet-title {
  font-family: $vchoney-semibold-fontfamily;
  font-size: unit(10);
  line-height: unit(11);
  color: $influencer-color-3;
  text-align: center;
  padding: 0 unit(5) unit(12);

  @include respond-to(wide) {
    font-size: unit(19);
    line-height: unit(20.5);
    padding: 0 unit(5) unit(22.25);
  }
}

.influencer-v2__closet-section {
  background-color: $influencer-color-4;
  display: block;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  overflow-x: hidden;
}

.influencer-v2__closet-cta-wrapper {
  width: 95vw;
  margin: 0 auto unit(9.5);
}

.influencer-v2__closet-cta {
  margin: 0 auto;
  width: fit-content;
  padding-top: unit(8.5);
  padding-bottom: unit(12);

  @include respond-to(desktops) {
    padding-top: unit(12.75);
    padding-bottom: unit(16);
  }

  .web_ui__Button__button {
    border-radius: $new-visual-button-border;
  }

  .web_ui__Button__filled {
    background-color: $influencer-color-2;
  }

  .web_ui__Button__default {
    font-size: unit(3.5);

    @include respond-to(wide) {
      font-size: unit(4);
      height: unit(12.5);
    }
  }
}

.influencer-v2__closet-cards-wrapper {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: unit(0);
  padding-left: unit(5);

  @include respond-to(wide) {
    @include content-container;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: unit(4);
  }
}

.influencer-v2__closet-slider-wrapper-item {
  padding-right: unit(5);

  @include respond-to(wide) {
    padding-right: 0;
  }
}

.influencer-v2__product-card {
  height: fit-content;
  width: unit(36.75);

  a {
    text-decoration: none;
  }

  @include respond-to(wide) {
    width: unit(45.75);
  }
}

.influencer-v2__product-card-image {
  position: relative;
  width: unit(36.75);
  height: unit(47.5);
  border-radius: unit(3.75);
  overflow: hidden;

  @include respond-to(wide) {
    width: unit(45.75);
    height: unit(59);
  }
}

.influencer-v2__product-card-title {
  color: $influencer-color-3;
  font-weight: 375;
  font-size: unit(3);
  line-height: unit(3.75);
  margin-top: unit(2);
  text-decoration: none;

  @include respond-to(wide) {
    font-size: unit(3.5);
    line-height: unit(4.5);
    margin-top: unit(3);
  }
}

.influencer-v2__fave-icon {
  position: absolute;
  width: unit(7.25);
  height: unit(7.25);
  background: palette(greyscale-level-6);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: unit(2.5);
  bottom: unit(2.5);

  @include respond-to(wide) {
    width: unit(9.5);
    height: unit(9.5);
    right: unit(3.25);
    bottom: unit(2.5);
  }
}

.influencer-v2__tips-section {
  display: block;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  overflow-x: hidden;
  background: $influencer-color-4;
}

.influencer-v2__tips-inner {
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: unit(21.5);
  padding-left: unit(5);
  padding-right: unit(5);
  box-sizing: border-box;

  @include respond-to(desktops) {
    display: block;
    padding-bottom: unit(19);
  }
}

.influencer-v2__tips-card {
  display: block;
  box-sizing: border-box;
  background: palette(greyscale-level-6);
  border-radius: unit(3.75);
  overflow: hidden;
  width: 100%;

  @include respond-to(tablets-up) {
    border-radius: unit(4);
    height: unit(148);
    margin: 0 auto;
    max-width: unit(319);
    display: flex;
    flex-direction: row-reverse;
  }
}

.influencer-v2__tips-card-content {
  background: palette(greyscale-level-6);
  position: relative;
  height: 100%;
  min-height: unit(86.75);
  padding: unit(6.25) unit(4.75) unit(4) unit(5.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  @include respond-to(tablets-up) {
    width: 50%;
    height: 100%;
    padding: unit(9.5);
  }

  p {
    margin-top: 0;
  }
}

.influencer-v2__tips-card-content-inner {
  display: block;

  @include respond-to(tablets-up) {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
}

.influencer-v2__tips-title {
  color: $influencer-color-3;
  font-size: unit(4);
  font-weight: 375;
  line-height: unit(5);
  text-align: left;

  @include respond-to(tablets-up) {
    font-size: unit(6);
    line-height: unit(12.5);
  }
}

.influencer-v2-tips-card-images-wrapper {
  position: relative;
  height: 249px;
  width: 100%;

  @include respond-to(tablets-up) {
    width: 50%;
    height: 100%;
  }
}

.influencer-v2__tips-card-image {
  height: unit(62.25);
  width: 100%;
  position: absolute;

  @include respond-to(tablets-up) {
    width: 100%;
    height: 100%;
  }
}

.influencer-v2__tips-card-title {
  font-family: $vchoney-semibold-fontfamily;
  display: none;
  color: $influencer-color-3;
  font-size: unit(8);
  line-height: unit(9);
  text-align: left;
  margin: 0;
  padding: unit(8) 0 unit(3.75) 0;

  @include respond-to(tablets-up) {
    font-size: unit(12);
    line-height: unit(13);
    padding: unit(0) 0 unit(6) 0;
  }
}

.influencer-v2__tips-card-description {
  display: none;
  color: $influencer-color-3;
  font-size: unit(3.5);
  font-weight: 375;
  line-height: unit(5);
  text-align: left;
  max-width: unit(64);

  @include respond-to(tablets-up) {
    font-size: unit(4);
    line-height: unit(5.5);
    height: unit(25.75);
    max-width: unit(91);
    padding-bottom: unit(11);
  }
}

.influencer-v2__tips-card-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.influencer-v2__tips-card-nav-numbers {
  display: flex;
  justify-content: space-between;
  gap: unit(4.5);
  font-size: unit(3.5);

  @include respond-to(desktops) {
    font-size: unit(4);
    line-height: unit(5.5);
  }
}

.influencer-v2__tips-card-nav-number-disabled {
  color: palette(greyscale-level-5);
}

.influencer-v2__tips-card-nav-buttons {
  display: flex;
  gap: unit(3);
}

.influencer-v2__tips-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: unit(7.5);
  height: unit(7.5);
  border-radius: 50%;
  border: solid unit(0.375) $influencer-color-3;

  .web_ui__Icon__greyscale-level-1 svg {
    fill: $influencer-color-3;
    color: $influencer-color-3;
  }

  @include respond-to(desktops) {
    width: unit(8.75);
    height: unit(8.75);
  }
}

.influencer-v2__tips-button.disabled {
  border: solid unit(0.375) palette(greyscale-level-5);
}

.influencer-v2__inspiration {
  display: block;
}

.influencer-v2__inspiration-main {
  position: relative;
  width: 100vw;
  left: calc(-50vw + 50%);
  color: $influencer-color-3;

  &::after {
    content: '';
    width: 100vw;
    left: calc(-50vw + 50%);
    display: block;
    position: absolute;
    height: 100%;
    background-color: $influencer-color-4;
    bottom: 0;
    z-index: 0;
  }

  @include respond-to(desktops) {
    width: 100%;
    left: 0;
  }
}

.influencer-v2__inspiration-main-content {
  width: 100%;
  margin: 0 auto;
  padding: unit(0) unit(0) unit(15) unit(5);
  box-sizing: border-box;
  overflow: hidden;
  z-index: 1;
  position: relative;

  @include respond-to(desktops) {
    display: flex;
    padding: unit(0) unit(0) unit(23) unit(8);
    justify-content: space-between;
    align-items: center;
  }
}

.influencer-v2__inspiration-cards-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: calc(100vw - unit(5));
  display: block;
  overflow: visible;
  margin-bottom: unit(0);
  margin-top: unit(8);

  .slider-wrapper-item {
    padding-right: unit(5);
  }

  @include respond-to(desktops) {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: unit(156);
    padding-left: 0;
    gap: unit(9);
    margin: 0;

    .slider-wrapper-item {
      padding-right: unit(4);
    }
  }
}

.influencer-v2__inspiration-title {
  font-family: $vchoney-semibold-fontfamily;
  margin-bottom: unit(5.5);
  color: $influencer-color-3;
  font-size: unit(8);
  font-weight: 500;
  line-height: unit(9);

  @include respond-to(desktops) {
    font-size: unit(12);
    line-height: unit(13);
    margin-bottom: unit(8);
  }
}

.influencer-v2__inspiration-description {
  font-size: unit(3.5);
  font-weight: 375;
  line-height: unit(5);
  text-align: left;
  padding-right: unit(5);

  @include respond-to(tablets-up) {
    padding-right: unit(0);
    font-size: unit(4);
    line-height: unit(5.5);
    max-width: unit(91);
  }
}

.influencer-v2__inspiration-bottom {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  color: $influencer-color-1;
  padding: unit(18) unit(0) unit(15);
  z-index: 0;

  &::after {
    content: '';
    width: 100vw;
    left: calc(-50vw + 50%);
    display: block;
    position: absolute;
    height: 100%;
    background-color: $influencer-color-2;
    bottom: 0;
    z-index: -1;
  }
}

.influencer-v2__inspiration-cta-headline {
  font-family: $vchoney-semibold-fontfamily;
  margin: 0;
  font-size: unit(10);
  line-height: unit(11);
  margin-bottom: unit(11);
  margin-right: auto;
  margin-left: auto;
  font-weight: 500;
  text-align: center;

  @include respond-to(wide) {
    max-width: unit(264);
    line-height: unit(21);
    margin-bottom: unit(11);
    font-size: unit(19);
  }
}

.influencer-v2__inspiration-cta {
  width: fit-content;
  margin: 0 auto;

  .web_ui__Button__button {
    border-radius: $new-visual-button-border;
    padding-left: unit(6.5);
    padding-right: unit(6.5);

    @include respond-to(desktops) {
      padding-left: unit(10.5);
      padding-right: unit(10.5);
    }
  }

  .web_ui__Button__filled {
    background-color: $influencer-color-1;
  }

  .web_ui__Button__primary.web_ui__Button__inverse.web_ui__Button__filled .web_ui__Button__label {
    color: $influencer-color-2;
  }

  .web_ui__Button__default {
    font-size: unit(3.5);
    line-height: unit(5.5);
    font-weight: 500;

    @include respond-to(wide) {
      font-size: unit(4);
      height: unit(12.5);
    }
  }
}

.influencer-v2__fade-in {
  -webkit-animation: fade-in 1.7s both;
  animation: fade-in 1.7s both;
}

.influencer-v2__fade-out {
  -webkit-animation: fade-out 1.1s both;
  animation: fade-out 1.1s both;
}

.influencer-v2__block {
  display: block;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.influencer-v2__disclaimer-section {
  position: relative;
  padding-top: unit(3);
  padding-bottom: unit(4.75);

  &::after {
    content: '';
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    display: block;
    position: absolute;
    height: 100%;
    background: palette(greyscale-level-6);
    bottom: 0;
    z-index: 0;
  }

  @include respond-to(desktops) {
    padding-top: unit(5);
    padding-bottom: unit(15.5);
  }
}

.influencer-v2__disclaimer-text {
  color: palette(greyscale-level-2);
  z-index: 1;
  position: relative;
  font-size: unit(2.5);
  font-weight: 375;
  line-height: unit(5.5);
  text-align: center;

  @include respond-to(desktops) {
    font-size: unit(3);
    line-height: unit(7.5);
    text-align: center;
  }
}
