.empty-state {
  padding: 60px 0;
  margin: $half-gutter 0;
  color: palette(greyscale-level-3);
  text-align: center;
  border-radius: $box-border-radius;
}

.empty-state__content {
  width: $phone-breakpoint;
  margin: 0 auto;
  line-height: $line-height-medium;
}
