// Block level navigation lists
//
//  extends .nav
//

.nav-stacked {
  display: block;
  color: palette(greyscale-level-2);

  .nav-item {
    display: block;

    // Until `is-hidden` does not have `!mportant` ...
    &.is-hidden {
      display: none;
    }
  }

  .nav-link {
    @include ellipsis;
    position: relative;
    z-index: map-get($zindex, bump);
    display: block;
  }

  &.nav-detailed {
    .nav-link {
      padding-right: ($half-gutter + $gutter); // space for bubble

      &:after {
        position: absolute;
        top: 50%;
        right: 0;
        margin: -($quarter-gutter) 0 0;
      }
    }
  }
}
