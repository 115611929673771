.profile-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  text-align: center;
}

.profile-empty-state--button {
  width: 30%;
}
