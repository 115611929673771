//
// Unify Google Places autocomplete styles with dropmenu
//
// **Docs**:
// https://google-developers.appspot.com/maps/documentation/javascript/places-autocomplete#style_autocomplete
//
// *1* Workaround, so styles are not applied in "new" pages
//

.pac-container {
  font-family: $default-font-family;
  color: palette(greyscale-level-3);
  border-top: 0;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 1px 2px palette-light(greyscale-level-1, 0.13);
  // above `.dropmenu and modal
  z-index: map-get($zindex, 'google-autocomplete');
}

.pac-logo {
  &:after {
    margin: 2px 0 5px;
  }
}

.pac-icon-marker {
  display: none;
}

.pac-item {
  cursor: pointer;
}

.pac-item,
.pac-item-query {
  font-size: inherit;
  color: inherit;
}

.pac-item-query {
  font-weight: $font-weight-bold;
  color: palette(greyscale-level-2);
}

body:not(.fullpage-layout) {
  // *1*
  .pac-container {
    box-sizing: content-box;

    // margin and padding makes sure positioned autocomplete
    // box expand to the sides of .dropmenu and aligns nicely
    padding: 0 6px;
    margin: 0 -5px;
  }
}
