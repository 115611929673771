$header-notification-dropdown-color: palette(greyscale-level-3);
$header-notification-dropdown-background-color: palette(greyscale-level-6);
$header-notification-dropdown-min-width: unit(75);
$header-notification-dropdown-border-radius: unit(0.75);
$header-notification-dropdown-transition-time: 250ms;
$header-notification-dropdown-border-color: palette(greyscale-level-6);
$header-notification-dropdown-border-width: unit(0.25);

$header-notification-dropdown-container-max-height: 60vh;
$header-notification-dropdown-button-container-padding: unit(4);
$header-notification-dropdown-button-container-padding-top: unit(2);
