.l-search {
  display: flex;
  @include sticky;
  top: $header-height;
  z-index: map-get($zindex, sticky);
  height: $l-search-height;

  @include respond-to(desktops) {
    display: none;
  }
}
