.payments-explanation__section {
  position: relative;
  padding: 0;
}

// This class is also used in the info_post
.payments-explanation__section-content {
  padding: 0 $gutter;
  text-align: start;

  p {
    margin: $payments-paragraph-space 0 0 !important;
  }
}

.payments-explanation__container {
  max-width: $payments-explanation-content-width;
  margin: 0 auto;
}

// The classes below are hardcoded in Infoboard Editor on Core Admin.
// Removing these classes will break the visuals on the Infoboard page.
// Clean up is prevented until further notice from the Product team.

.payments-explanation__media {
  margin-bottom: $gutter * 3;

  @include respond-to(portables) {
    margin-bottom: $gutter;
  }
}

.payments-explanation__paragraph {
  margin: 0 0 $payments-explanation-whitespace;
}

.payments-explanation__paragraph--pre {
  margin: 0;
  white-space: pre-line;
}

.payments-explanation__paragraph-heading {
  @include font-size($font-size-big);
  @include font-smoothing;
  margin-bottom: $half-gutter;

  @include respond-to(phones) {
    @include font-size($font-size-heading);
  }
}

.payments-explanation__action {
  padding: $payments-explanation-whitespace 0 $payments-explanation-whitespace * 2;
  text-align: center;
}
