.onboarding-modal__dialog--rounded {
  border-radius: 12px;
}

.onboarding-modal__slide-content-title {
  font-size: 40px;
  font-weight: 500;
  line-height: 46px;
  color: palette(greyscale-level-1);
  margin: 0;
}

.onboarding-modal__text-slide-visual-content {
  margin-top: auto;
  z-index: -1;

  @media only screen and (max-height: 790px) {
    position: sticky;
    bottom: -70px;
  }

  @media only screen and (max-width: 375px) {
    bottom: -35px;
  }
}

.onboarding-modal__text-slide-video-content {
  video {
    width: 100%;
  }

  @media only screen and (max-height: 760px) {
    video {
      width: 75%;
    }
  }

  @media only screen and (max-width: 375px) {
    video {
      width: 45%;
    }
  }
}

.onboarding-modal__text-slide-content {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  max-height: calc(100vh - 248px);

  @media only screen and (max-width: 414px) {
    max-height: calc(100vh - 300px);
  }
}

.onboarding-modal__slide-content {
  position: relative;
  display: flex;
  height: 100%;
  max-height: calc(100vh - #{$onboarding-modal-slide-content-spacing});
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.onboarding-modal__slide-video-content {
  &::before {
    @include stretch(0, 0, 75%);
    background: linear-gradient(
      180deg,
      $onboarding-modal-slide-content-gradient-color 0%,
      transparent 100%
    );
    content: '';
  }

  &::after {
    @include stretch(75%, 0, 0);
    background: linear-gradient(
      180deg,
      transparent 0%,
      $onboarding-modal-slide-content-gradient-color 100%
    );
    content: '';
  }

  video {
    width: 100%;
  }
}

.single-step-onboarding-modal__content {
  height: 100%;
  max-height: calc(100vh - 248px);
  overflow: auto;
}

.single-step-onboarding-modal__content-separator {
  height: 32px;

  @media only screen and (min-width: 720px) and (max-height: 670px) {
    height: 24px;
  }
}
