.photo-box {
  position: relative;
}

.photo-box__image-container {
  padding: unit(1);
}

.photo-box__delete-button {
  position: absolute;
  top: unit(2.5);
  right: unit(2.5);
}

.photo-box__action-button {
  position: absolute;
  right: unit(2.5);
  bottom: unit(2.5);
}

.photo-box__loader {
  @include stretch;
  display: flex;
  justify-content: center;
  align-items: center;
}
