.conversation-education__background {
  background: palette(greyscale-level-4);
}

.conversation-education__item-photo {
  border-radius: unit(1);
  overflow: hidden;
}

.conversation-education__item-photo-text {
  position: absolute;
  bottom: 0;
}

.conversation-education__tear {
  height: $conversation-education-header-tear-height;
  background-image: url('../../../next/public/assets/conversation/education/tear.svg');
  // Note: 1px offset is due to inconsistent calculation of background position
  background-position: bottom -1px center;
  background-repeat: no-repeat;
  background-size: cover;
}

[data-theme='dark'] .conversation-education__tear {
  background-image: url('../../../next/public/assets/conversation/education/dark/tear.svg');
}
