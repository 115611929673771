$stories-overlay-background-color: palette-light(greyscale-level-1, 0.48);

.stories__overlay {
  @include stretch();

  position: fixed;
  z-index: map-get($zindex, overlay);
  background-color: $stories-overlay-background-color;
}

.stories__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 375px;
}

.stories__background {
  background-color: palette-light(ca10);
}

.stories__navigation {
  @include respond-to(phones) {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
  }
}

.stories__navigation ul {
  max-width: none;
  justify-content: center;
}

.stories__close-navigation {
  position: absolute;
  top: 0;
  right: 0;
}
