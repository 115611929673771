$checkout-container-vertical-padding: unit(8);
$checkout-container-phones-vertical-padding: unit(3);
$checkout-container-horizontal-padding: unit(3);
$checkout-container-v2-phones-vertical-padding: unit(4);
$checkout-container-v2-horizontal-padding: unit(4);

$checkout-summary-max-width: unit(100);
$checkout-summary-min-width: unit(75);
$checkout-summary-top-padding: calc(
  #{$header-height} + #{$checkout-container-vertical-padding} + #{$checkout-container-vertical-padding}
);

$checkout-notification-max-width: unit(180);
$checkout-notification-bottom: unit(6);
$checkout-notification-margin-horizontal-phones: unit(2);
$checkout-notification-margin-horizontal-tablets-up: unit(5);
