.l-header {
  @include sticky;
  top: 0;
  z-index: map-get($zindex, header);
}

.l-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $l-header-background-color;
  border-bottom: 1px solid $divider-color;

  &.is-active {
    .l-header__navigation {
      @include ios-smooth-scroll;
      display: block;
      height: calc(100vh - #{$l-header-main-area-height});
      padding-bottom: $l-header-mobile-navigation-bottom-spacing;
      overflow-y: scroll;

      @include respond-to(desktops) {
        height: auto;
        padding-bottom: 0;
        overflow-y: visible;
      }
    }
  }
}

.l-header--placeholder {
  min-height: $l-header-placeholder-mobiles-height;

  @include respond-to(desktops) {
    min-height: $l-header-placeholder-desktops-height;
  }
}

.l-header__main {
  padding-top: $l-header-vertical-spacing;
  padding-bottom: $l-header-vertical-spacing;

  @include respond-to(desktops) {
    height: $l-header-main-area-height;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.l-header__navigation {
  display: none;
  box-sizing: border-box;
  border-top: 1px solid $divider-color;
  min-height: 40px;

  @include respond-to(desktops) {
    display: block;
  }
}

.l-header__logo {
  height: $l-header-logo-height;
  display: flex;
  align-items: center;
}

.l-header__logo {
  @include respond-to(desktops) {
    margin-right: $l-header-section-spacing;
  }
}

.l-header__logo-container {
  width: $l-header-logo-width * $l-header-logo-ratio;
  height: $l-header-logo-height;
}
