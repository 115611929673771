$timeline-wrapper-padding-bottom: unit(4);
$timeline-item-padding-top: unit(4);

$timeline-mark-min-height: unit(5);
$timeline-mark-padding-right: unit(2);
$timeline-mark-padding-left: unit(4);

$timeline-content-padding: unit(4);

$timeline-border-width: unit(0.25);

$timeline-line-color: palette(primary-default);

$timeline-connector-height: unit(4);

$timeline-connector-width: unit(6);
$timeline-connector-padding-left: unit(4);
