@mixin shadow($style: default) {
  @if $style == default {
    box-shadow: $shadow-default-offset-x
      $shadow-default-offset-y
      $shadow-default-blur
      rgba($shadow-default-color, $shadow-default-alpha);
  } @else if $style == elevated {
    box-shadow: $shadow-elevated-offset-x
      $shadow-elevated-offset-y
      $shadow-elevated-blur
      rgba($shadow-default-color, $shadow-default-alpha);
  } @else if $style == lifted {
    box-shadow: $shadow-lifted-offset-x
      $shadow-lifted-offset-y
      $shadow-lifted-blur
      rgba($shadow-default-color, $shadow-lifted-alpha);
  }
}
