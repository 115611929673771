$half-sidebar: 160px;
$header-icons-size: 16px;
$placeholder-input-color: palette(greyscale-level-5);

.thread__wrapper {
  position: relative;
  display: flex;
  height: 100%;
}

.thread__messages-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.thread__messages-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.thread__messages-main {
  position: relative;
  width: 100%;
  flex: 3;
  display: flex;
  height: 100%;
}

.thread__content {
  box-sizing: border-box;
  width: 100%;
  padding: 0 unit(4);
  overflow: auto;

  @include respond-to(portables) {
    @include ios-smooth-scroll;
  }
}

.thread__conversation-info {
  height: 100%;
  overflow: auto;
}
