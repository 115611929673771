a {
  color: palette(primary-default);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: underline;

    @include respond-to(desktops) {
      text-decoration: underline;
    }
  }
}
