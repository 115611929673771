.user-search__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: $user-search-container-min-height;
}

.user-search__body {
  display: flex;
  flex-grow: 1;

  .user-grid {
    align-self: flex-start;
    width: 100%;
  }
}

.user-search__body-state--center {
  align-self: center;
  width: 100%;
}
