.user-created-content {
  @include type(type('body'));
  @include word-wrap();

  .is-webview.browser-android & {
    color: palette(greyscale-level-2);
  }

  ul,
  ol {
    padding-left: 0;
    margin: unit(2) 0 unit(4) unit(2);
  }

  li {
    margin-top: $quarter-gutter;
    list-style: disc;

    li {
      list-style: circle;
    }
  }

  ol {
    counter-reset: list-counter;

    li {
      margin-left: ($half-gutter + $content-bullet-size);
    }

    & > li {
      list-style-type: none;
      counter-increment: list-counter;

      & + li {
        margin-top: unit(4);
      }

      &::before {
        @include font-size($font-size-micro);
        @include fully-rounded;
        @include font-smoothing(false);
        display: inline-block;
        width: $content-bullet-size;
        height: $content-bullet-size;
        margin: 0 $half-gutter 0 (-$half-gutter - $content-bullet-size);
        font-weight: initial;
        line-height: $content-bullet-size;
        color: palette(greyscale-level-6);
        text-align: center;
        background: palette(greyscale-level-2);
        content: counter(list-counter);

        .is-webview & {
          font-weight: $font-weight-demibold;
          line-height: $content-bullet-size + 1; // Center number inside bullet on iOS Webview
        }

        .is-webview.browser-android & {
          line-height: $content-bullet-size + 2; // Center number inside bullet on Android Webview
        }
      }
    }
  }

  img {
    border-radius: 2px;
  }

  p {
    margin: 0 0 unit(2);
  }

  strong {
    font-weight: $font-weight-demibold;
  }

  h2 {
    @include type(type('title'));
    margin: 0 0 unit(2);
  }

  table {
    margin: unit(4) 0;
    border-collapse: collapse;
  }

  th {
    @include type(type('title'));
    padding: unit(2);
    text-align: left;
    background: $user-created-content-table-header-bg;
    border: $user-created-table-border-style;
  }

  td {
    @include type(type('body'));
    padding: unit(2);
    border: $user-created-table-border-style;
  }

  .table-wrapper {
    overflow: auto;
  }

  :target {
    display: inline-block;
    padding-top: $l-header-main-area-height + $l-search-height;
    margin-top: -($l-header-main-area-height + $l-search-height);

    @include respond-to(desktops) {
      padding-top: $l-header-main-area-height + $l-header-navigation-height;
      margin-top: -($l-header-main-area-height + $l-header-navigation-height);
    }
  }

  details[open] > summary:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGZpbGw9IiM5OTk5OTkiIGQ9Ik0xOC4wMDIgMTQuNDVsLTYuMDAxLTUuOTQ5TDYgMTQuNDUxbDEuMDYgMS4wNSA0Ljk0MS00Ljg5NyA0Ljk0IDQuODk3eiIvPgo8L3N2Zz4K);
  }

  summary {
    @include type(type('title'));

    position: relative;
    outline: none;
    padding: $cell-padding;
    color: palette(greyscale-level-1);
    cursor: pointer;

    &::-webkit-details-marker,
    &::marker {
      display: none;
      content: '';
    }

    &::after {
      content: '';
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGZpbGw9IiM5OTk5OTkiIGQ9Ik0xOC4wMDIgOS41NTJsLTEuMDYxLTEuMDUxLTQuOTQgNC44OTdMNy4wNiA4LjUwMSA2IDkuNTUybDYuMDAxIDUuOTQ5eiIvPgo8L3N2Zz4K);
      width: unit(6);
      height: unit(6);
      position: absolute;
      margin-top: -(unit(3));
      top: 50%;
      right: 0;
    }

    &:hover {
      background: $cell-navigating-hover-background;
    }
  }
}
