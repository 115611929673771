.input-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: map-get($zindex, dropdown);
}

.input-dropdown--direction-up {
  top: auto;
  bottom: 100%;
}

.input-dropdown__content {
  display: flex;
  height: $input-dropdown-content-mobile-height;
  overflow-y: hidden;
  flex-direction: column;

  @include respond-to(tablets-up) {
    height: initial;
    max-height: $input-dropdown-content-max-height;
  }
}

.input-dropdown__content--scrollable {
  overflow-y: auto;
}
