.pile {
  margin: 0;
  list-style: none;
}

.pile__element {
  margin: 0;

  & + & {
    border-top: 1px solid $pile-separator-color;
  }
}
