@use 'sass:math';

.horizontal-scroll__rail {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  flex-shrink: 0;
}

.horizontal-scroll__controls {
  @include size(100%);
  @include stretch;
  position: absolute;
  display: flex;
  pointer-events: none;
  justify-content: space-between;
  align-items: center;
}

.horizontal-scroll__handle {
  padding: unit(2);
  pointer-events: auto;
  cursor: pointer;

  @include respond-to(tablets-up) {
    padding: unit(4);
  }

  @include fully-rounded;
  position: absolute;
  pointer-events: auto;
  border: 1px solid palette-light(greyscale-level-6);
  background-color: palette(greyscale-level-6);
  display: flex;
  align-items: center;
  justify-content: center;
  height: unit(6);
  width: unit(6);
  margin: unit(2);
  padding: unit(4);
}

.horizontal-scroll__left-handle {
  left: 0;
}

.horizontal-scroll__right-handle {
  right: 0;
}

.horizontal-scroll__content {
  @include hide-scrollbar;
  display: flex;
  width: 100%;
  overflow-x: auto;
}

// This is potentially redundant and leads to overflow issues (Tooltip not visible) on mobile browsers
// TODO revise and remove smooth scroll if it does not lead to regressions
.horizontal-scroll__content--ios-smooth-scroll {
  @include ios-smooth-scroll;
}

.horizontal-scroll__item {
  display: block;
  padding-right: unit(2);
  flex-shrink: 0;

  @include respond-to(tablets-up) {
    padding-right: unit(3);
  }
}

.horizontal-scroll__item--fit-content {
  display: flex;
  flex-direction: column;
}

.horizontal-scroll__content--full-width-alignment {
  .horizontal-scroll__rail {
    gap: 0 unit(2);

    @include respond-to(tablets-up) {
      gap: 0 unit(3);
    }
  }

  .horizontally-scrollable-items__item {
    @include respond-to(wide) {
      // we need to consider gaps between items otherwise all of them won't fit
      // unit(3) - is the size of the gap, 5 - number of gaps between items
      // divided of 6 because we want to "distribute" them equaly
      width: unquote('calc(#{fractional-width(6)} - #{unit(3) * math.div(5, 6)})');
    }
  }

  .horizontal-scroll__item {
    padding-right: unset;
  }
}
