$tab-border-color: palette(greyscale-level-1, 0.1);
$tab-border-size: 2px;
$tab-border-size-small: 1px;

.nav-tabs {
  position: relative;
  display: block;
  margin-bottom: $gutter;

  & > .nav-item {
    @include font-size($font-size-medium);
    @include font-smoothing;
    padding: 0 $half-gutter $tab-border-size;
    font-weight: $font-weight-bold;
    line-height: 25px;
    color: palette(greyscale-level-3);

    @include respond-to(portables) {
      line-height: 30px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    & > .nav-link {
      position: relative;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  // bottom borders
  // (using pseudo element, so its easier to overlap active tab and tabs block borders)
  &:after {
    @include content();

    bottom: 0;
    left: 0;
    width: 100%;
    height: $tab-border-size;
    background: $tab-border-color;
  }

  // current tab
  & > .active {
    & > .nav-link {
      color: palette(greyscale-level-3);

      &:after {
        @include content();

        bottom: -$tab-border-size;
        z-index: map-get($zindex, medium);
        width: 100%;
        height: $tab-border-size;
        background: palette(primary-default);
      }

      .is-webview.browser-android & {
        color: palette(greyscale-level-2);
      }
    }
  }
}

// Modifiers
.nav-tabs--small {
  & > .nav-item {
    @include font-size($font-size-small);
    @include font-smoothing;
    padding: 0 $quarter-gutter $tab-border-size-small;
    font-weight: $font-weight-bold;
    line-height: 20px;
    text-transform: uppercase;

    @include respond-to(portables) {
      @include font-size($font-size-default);
    }
  }

  &:after {
    height: $tab-border-size-small;
  }

  & > .active {
    & > .nav-link {
      &:after {
        bottom: -$tab-border-size-small;
        height: $tab-border-size-small;
      }
    }
  }
}

@include respond-to(phones) {
  .nav-tabs {
    margin-bottom: $quarter-gutter;
    white-space: nowrap;

    & > .nav-item {
      padding-right: $quarter-gutter;
      padding-left: $quarter-gutter;

      .nav-link[data-short-name] {
        font-size: 0;
        line-height: 20px;

        &:before {
          @include font-size($font-size-default);
          content: attr(data-short-name);
        }
      }
    }
  }
}

.tab-content {
  display: none;
  min-height: 200px; // no jumping for shorter content

  &.active {
    display: block;
  }
}
