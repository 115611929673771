.domain-selection-link {
  display: flex;
  align-items: center;
  color: palette(greyscale-level-1);
  text-decoration: none;
  padding: 1px 0;
  font-weight: 500;
  &:hover,
  &.active {
    color: palette(greyscale-level-1);
    text-decoration: none;
  }

  span {
    margin-right: 8px;
  }
}
