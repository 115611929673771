.order-details-page {
  display: flex;
  flex-direction: row;
  gap: $gutter;

  @include respond-to(portables) {
    flex-direction: column;
  }

  section {
    flex: 2;
  }

  aside {
    flex: 1;
  }
}

.order-details-page__opposite-user__msg-button,
.order-details-page__payment-summary {
  .web_ui__Container__container {
    padding-top: 0;
  }
}

.order-details-page__bundle-summary,
.order-details-page__manage-order {
  .web_ui__Accordion__wide {
    padding: 0;
  }
}
