.braze-conversation-message {
  @include clearfix;
  @include word-wrap;
  word-break: normal;
  position: relative;
  float: left;
  max-width: $braze-conversation-message-max-width;
  min-height: $braze-conversation-message-min-height;

  @include respond-to('phones') {
    max-width: 70%;
  }
}

.braze-conversation-message__banner-overlay {
  position: relative;
  width: auto !important;

  img {
    max-height: $braze-conversation-message-banner-overlay-max-height-phones;

    @include respond-to(tablets-up) {
      max-width: $braze-conversation-message-banner-overlay-max-width;
      max-height: $braze-conversation-message-banner-overlay-max-height;
    }
  }
}

.braze-conversation-message__video-thumbnail-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.braze-conversation-message__video {
  display: block;
  width: 100%;
  background-color: black;
}
