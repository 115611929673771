.pickup-point-map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.pickup-point-map__loader {
  @include stretch;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.pickup-point-map__location-pin--medium {
  position: absolute;
  z-index: map-get($zindex, location-pin);
  cursor: pointer;
  transform: translate(-50%, -100%);
}

.pickup-point-map__location-pin--medium {
  width: $pickup-point-map-location-pin-medium-width;
  height: $pickup-point-map-location-pin-medium-height;
  text-align: center;
}

.pickup-point-map__location-pin--medium--active {
  z-index: map-get($zindex, location-pin-active);

  .pickup-point-map__location-pin-background {
    path:first-of-type {
      fill: unset;
    }
  }
}

.pickup-point-map__location-pin--hovered {
  z-index: map-get($zindex, location-pin-hover);
}

.pickup-point-map__location-pin-background {
  width: $pickup-point-map-location-pin-medium-width;
  height: $pickup-point-map-location-pin-medium-height;

  path:first-of-type {
    fill: $pickup-point-map-location-pin-background-color;
  }
}

.pickup-point-map__location-pin__carrier-icon {
  position: absolute;
  transform: translate(50%, -180%);
}

.pickup-point-map__address-pin {
  position: absolute;
  z-index: map-get($zindex, address-pin);
  width: $pickup-point-map-address-pin-width;
  height: $pickup-point-map-address-pin-height;
  transform: translate(-50%, -100%);

  path:last-of-type {
    fill: $pickup-point-map-address-pin-fill;
  }

  circle:first-of-type {
    fill: $pickup-point-map-address-pin-fill;
  }
}

.pickup-point-map__current-location {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: map-get($zindex, current-location);

  circle:first-of-type {
    opacity: 0.2;
    fill-opacity: 0.97;
  }

  circle:last-of-type {
    stroke: $pickup-point-map-current-location-inside-circle-stroke;
    stroke-width: $pickup-point-map-current-location-inside-circle-stroke-width;
  }
}

.pickup-point-map__controls {
  position: absolute;
  top: $pickup-point-map-controls-top;
  right: $pickup-point-map-controls-right;
  padding: $pickup-point-map-controls-padding;
}

.pickup-point-map__search-area-container {
  @include center(horizontal);
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.pickup-point-map__search-area-button {
  @include shadow(lifted);
  margin: $pickup-point-map-search-area-button-margin;
  border-radius: $button-border-radius;
  pointer-events: auto;
}

.pickup-point-map__info-window {
  position: absolute;
  z-index: map-get($zindex, info-window);
  width: $pickup-point-map-info-window-width;
  height: $pickup-point-map-info-window-height;
  transform: translate(-50%, -132px);
}
