$offline-verification-dark-color-experimental: palette(offlineauthenticitydarkexperimental);

$offline-verification-container-color: palette-light(br20);
$offline-verification-container-color-dark: $offline-verification-dark-color-experimental;

.offline-verification-status {
  overflow: hidden;
  background-color: $offline-verification-container-color;
}

.offline-verification-status--dark {
  background-color: $offline-verification-container-color-dark;
}
