.page-container {
  max-width: $page-width;
  margin: 0 auto;

  @include respond-to(portables) {
    padding: 0 unit(6);
  }

  :target {
    display: inline-block;
    padding-top: $l-header-main-area-height + $l-search-height;
    margin-top: -($l-header-main-area-height + $l-search-height);

    @include respond-to(desktops) {
      padding-top: $l-header-main-area-height + $l-header-navigation-height;
      margin-top: -($l-header-main-area-height + $l-header-navigation-height);
    }
  }
}
