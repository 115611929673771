.rules-container {
  width: 100%;
}

.cell-title {
  padding-bottom: 8px;
  min-width: 55%;
}

.more-information {
  a {
    text-decoration: none !important;
  }
}

.tax-rules__success-example {
  background-color: palette-light(em10);
}

.tax-rules-grid {
  display: grid;
  gap: unit(4);
}

.tax-rules-grid__desktops {
  grid-template-columns: repeat(3, 1fr);
}

.tax-rules-grid__tablets {
  grid-template-columns: repeat(2, 1fr);
}

.tax-rules-grid__mobiles {
  grid-template-columns: 1fr;
}
