.buyer-protection-modal {
  ul,
  li {
    margin-top: 0;
  }

  b {
    font-weight: 500;
  }
}
