// scss-lint:disable NestingDepth, QualifyingElement, SelectorDepth

.box {
  position: relative;

  display: block;
  padding: $box-padding;
  margin-top: $gutter;

  background: $box-background;
  border-radius: $box-border-radius;
  box-shadow: $box-shadow;

  &:first-child {
    margin-top: 0;
  }
}

.box--form {
  @include respond-to(tablets-up) {
    max-width: 340px;
    margin-right: auto;
    margin-left: auto;
  }
}
