@import 'abstracts/index';

.privacy-policy-content {
  background: palette(greyscale-level-6);

  details div {
    padding: 0 unit(4) unit(4);
  }

  details[open] > summary:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGZpbGw9IiM5OTk5OTkiIGQ9Ik0xOC4wMDIgMTQuNDVsLTYuMDAxLTUuOTQ5TDYgMTQuNDUxbDEuMDYgMS4wNSA0Ljk0MS00Ljg5NyA0Ljk0IDQuODk3eiIvPgo8L3N2Zz4K);
  }

  summary {
    @include type(type('title'));

    outline: none;
    padding: $cell-padding;
    color: palette(greyscale-level-1);
    cursor: pointer;

    &::-webkit-details-marker,
    &::marker {
      display: none;
      content: '';
    }

    &::after {
      content: '';
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGZpbGw9IiM5OTk5OTkiIGQ9Ik0xOC4wMDIgOS41NTJsLTEuMDYxLTEuMDUxLTQuOTQgNC44OTdMNy4wNiA4LjUwMSA2IDkuNTUybDYuMDAxIDUuOTQ5eiIvPgo8L3N2Zz4K);
      width: unit(6);
      height: unit(6);
      float: right;
    }

    &:hover {
      background: $cell-navigating-hover-background;
    }
  }
}
