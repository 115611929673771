.landing__slider-wrapper {
  @include ios-smooth-scroll;
  overflow-x: scroll;
  overflow-y: hidden;
  text-align: center;

  &::-webkit-scrollbar {
    display: none;
  }
}

.about__section {
  max-width: $page-width;
  margin: 0 auto;

  @include respond-to(portables) {
    padding: 0 unit(6);
  }
}

.about__facts {
  display: flex;
  flex-wrap: wrap;
}

.about__fact-block {
  display: flex;
  flex-wrap: nowrap;
  width: 50%;

  @include respond-to(phones) {
    flex-wrap: wrap;
    width: 100%;
  }
}

.about__fact-icon {
  padding-right: unit(4);
}

.about__leadership-slider {
  flex-wrap: nowrap;

  @include respond-to(portables) {
    display: inline-flex;
  }

  @include respond-to(desktops) {
    display: flex;
  }
}

.about__leadership-card {
  position: relative;
  display: block;
  margin-right: unit(2);
  width: unit(55);

  @include respond-to(tablets) {
    width: unit(75);
  }

  @include respond-to(desktops) {
    width: 100%;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.about__leadership-card__info {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0 unit(2) unit(10) unit(2);
  color: palette-light(greyscale-level-6);
}

.about__hero-section {
  min-height: $hero-height;
  padding: $hero-padding-vertical $hero-padding-horizontal;
  line-height: normal;
  text-align: center;

  @include respond-to(phones) {
    padding: ($gutter * 3) $gutter;
  }
}

.about__hero-section--with-background {
  background-color: RGB($color-background-site);
}

// these classes are used in the info_post
.about__paragraph,
.new-about__paragraph {
  @include font-size($font-size-large);
  @include font-smoothing;

  margin: ($gutter * 1.5) 0;

  line-height: $landing-line-height;
  color: palette(greyscale-level-2);
}

.about__hero-image {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}
