@use 'sass:math';

@function get-column-width($columns, $gap) {
  $gaps: $gap * ($columns - 1);
  $total-columns-width: calc(100% - $gaps);

  @return calc($total-columns-width / $columns);
}

.item-view-items {
  display: flex;
  flex-wrap: wrap;
  margin-top: unit(4);
  margin-bottom: unit(4);
  gap: unit(4);
}

.item-view-items__item {
  box-sizing: border-box;
  width: get-column-width(2, unit(4));

  @include respond-to(tablets-up) {
    width: get-column-width(3, unit(4));
  }

  @include respond-to(wide) {
    width: get-column-width(4, unit(4));
  }
}

.item-page-horizontal-block .horizontally-scrollable-items__cta-content {
  background: white;
}

.item-view-items-horizontal__cta .horizontal-scroll__item {
  width: 100%;
  height: 100%;
}

.item-view-items-horizontal__item {
  box-sizing: border-box;
  width: fractional-width(2.5);

  @include respond-to(tablets-up) {
    width: fractional-width(3);
  }

  @include respond-to(desktops) {
    width: fractional-width(4);
  }

  @include respond-to(wide) {
    width: fractional-width(4.5);
  }
}
