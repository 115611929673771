.page-section__content {
  width: 100%;
}

.page-section__facts {
  display: flex;
  flex-wrap: nowrap;

  @include respond-to(phones) {
    flex-wrap: wrap;
  }
}

.page-section__fact-block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @include respond-to(tablets-up) {
    flex-wrap: nowrap;
    width: 50%;
  }
}

.page-section__fact-single-block {
  display: flex;
  flex-direction: column;

  @include respond-to(tablets-up) {
    flex-direction: row;
  }
}

.page-section__fact-icon {
  width: 100%;

  @include respond-to(tablets-up) {
    width: auto;
  }
}

.page-section__fact-illustration {
  flex-shrink: 0;
  text-align: center;
}
