@each $size, $responsive-text in $responsive-text-types {
  @each $type, $styles in $responsive-text {
    .c-text--responsive-#{$size}-#{$type} {
      @include type(map-get($styles, mobile));

      @include respond-to(tablets-up) {
        @include type(map-get($styles, desktop));
      }
    }
  }
}

.c-text--inverse {
  @include type(type(inverse));

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.c-text--truncated {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
