$closet-countdown-padding: unit(4);
$closet-countdown-content-line-height: 0.8;
$closet-countdown-content-width: 75%;
$closet-countdown-content-max-width: unit(220);
$closet-countdown-content-gap: unit(6);
$closet-countdown-title-line-height: 1.25;
$closet-countdown-button-padding-left: unit(4);
$closet-countdown-timer-unit-width: unit(10.5);
$closet-countdown-timer-unit-height: unit(11.5);
$closet-countdown-timer-unit-background-color: palette(greyscale-level-6);
$closet-countdown-timer-unit-color: palette(greyscale-level-1);
$closet-countdown-timer-unit-border-radius: unit(1);
$closet-countdown-timer-unit-name-line-height: 1.5;
