// scss-lint:disable ImportantRule

$spacing-directions: top, right, bottom, left;
$spacing-types: padding, margin;
$spacing-sizes: (
  small: $quarter-gutter,
  regular: unit(2),
  medium: $half-gutter,
  large: $gutter,
  x-large: (
    $gutter * 2,
  ),
  xx-large: (
    $gutter * 3,
  ),
  none: 0,
  auto: auto,
);

// UI spacing utilities
// .u-ui-{type}-{size}

@each $type in $spacing-types {
  @each $size-name, $size-value in $spacer-sizes {
    .u-ui-#{$type}-#{$size-name} {
      @include device-modifiers {
        #{$type}: $size-value !important;
      }
    }

    .u-ui-#{$type}-horizontal-#{$size-name} {
      @include device-modifiers {
        #{$type}-right: $size-value !important;
        #{$type}-left: $size-value !important;
      }
    }

    .u-ui-#{$type}-vertical-#{$size-name} {
      @include device-modifiers {
        #{$type}-top: $size-value !important;
        #{$type}-bottom: $size-value !important;
      }
    }

    @each $direction in $spacing-directions {
      .u-ui-#{$type}-#{$direction}-#{$size-name} {
        @include device-modifiers {
          #{$type}-#{$direction}: $size-value !important;
        }
      }
    }
  }
}

//
// Generates helper classes:
//
// .u-padding-#{$size}
// .u-padding-{$direction}-#{$size}
// .u-padding-horizontal-#{$size}
// .u-padding-vertical-#{$size}
// .u-padding-vertical-#{$size}@#{device-modifier}
//
// .u-margin-#{$size}
// .u-margin-{$direction}-#{$size}
// .u-margin-horizontal-#{$size}
// .u-margin-vertical-#{$size}
// .u-margin-vertical-#{$size}@#{device-modifier}
//

@each $type in $spacing-types {
  @each $size-name, $size-value in $spacing-sizes {
    .u-#{$type}-#{$size-name} {
      @include device-modifiers {
        #{$type}: $size-value !important;
      }
    }

    .u-#{$type}-horizontal-#{$size-name} {
      @include device-modifiers {
        #{$type}-right: $size-value !important;
        #{$type}-left: $size-value !important;
      }
    }

    .u-#{$type}-vertical-#{$size-name} {
      @include device-modifiers {
        #{$type}-top: $size-value !important;
        #{$type}-bottom: $size-value !important;
      }
    }

    @each $direction in $spacing-directions {
      .u-#{$type}-#{$direction}-#{$size-name} {
        @include device-modifiers {
          #{$type}-#{$direction}: $size-value !important;
        }
      }
    }
  }
}

// Generates helper gap classes:

// .u-gap-#{$size}
// .u-gap-row-#{$size}
// .u-gap-column-#{$size}

@each $size-name, $size-value in $spacing-sizes {
  .u-gap-#{$size-name} {
    gap: $size-value;
  }

  .u-gap-row-#{$size-name} {
    row-gap: $size-value;
  }

  .u-gap-column-#{$size-name} {
    column-gap: $size-value;
  }
}

.u-ui-margin-top-auto {
  margin-top: auto;
}
