$l-header-background-color: palette(greyscale-level-6);
$l-header-vertical-spacing: unit(1);
$l-header-logo-height: unit(11);
$l-header-main-area-height: unit(11) + (2 * $l-header-vertical-spacing);
$l-header-placeholder-mobiles-height: unit(26.25);
$l-header-placeholder-desktops-height: unit(25);
$l-header-section-spacing: unit(12);
$l-header-mobile-navigation-bottom-spacing: unit(40);
$l-header-navigation-height: unit(10);
$l-header-logo-ratio: 0.88;
$l-header-logo-width: 83px;

$l-search-height: 52px;
$header-height: 53px; // 52px + 1px border
$header-nav-height-desktops: 45px; //  // 44px + 1px border

$header-total-height-desktops: $header-height + $header-nav-height-desktops;
$header-total-height-portables: $header-height + $l-search-height;

$header-avatar-size: 30px;
$header-shadow-color: palette-light(greyscale-level-1, 0.1);
$header-item-color: palette(greyscale-level-3);
$header-item-active-color: palette(greyscale-level-1);
