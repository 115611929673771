.closet-countdown {
  position: relative;
  padding: $closet-countdown-padding;
}

.closet-countdown__content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $closet-countdown-content-gap;
  width: $closet-countdown-content-width;
  max-width: $closet-countdown-content-max-width;
  line-height: $closet-countdown-content-line-height;
  -webkit-font-smoothing: antialiased;
}

.closet-countdown__content--hidden-action {
  justify-content: flex-start;
}

.closet-countdown__title {
  @include font-size($font-size-heading);

  flex-shrink: 1;
  color: palette(greyscale-level-1);
  line-height: $closet-countdown-title-line-height;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closet-countdown__button {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  padding-left: $closet-countdown-button-padding-left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.closet-countdown__timer {
  display: flex;
  position: relative;
  margin: 0;
  z-index: map-get($zindex, bump);
}

.closet-countdown__timer-unit {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: $closet-countdown-timer-unit-width;
  height: $closet-countdown-timer-unit-height;
  margin: 0;
  background-color: $closet-countdown-timer-unit-background-color;
  color: $closet-countdown-timer-unit-color;
  border-radius: $closet-countdown-timer-unit-border-radius;
}

.closet-countdown__timer-unit-value {
  @include font-size($font-size-heading);

  margin: 0;
  font-weight: bold;
}

.closet-countdown__timer-unit-name {
  @include font-size($font-size-small);

  line-height: $closet-countdown-timer-unit-name-line-height;
  margin: 0;
}

.closet-countdown__image {
  position: absolute;
  right: 0;
  top: 0;
}
