.header-notification-dropdown {
  transform: translateY(0) translateX(-50%) scale(1);
  left: 50%;
  top: 100%;
  opacity: 1;
  padding: 0;
  margin: 0;
  min-width: $header-notification-dropdown-min-width;
  transition:
    transform cubic-bezier(0.35, 1.65, 0.65, 1.1) $header-notification-dropdown-transition-time,
    opacity ease $header-notification-dropdown-transition-time;
  position: absolute;
  box-sizing: border-box;
  max-width: none;
  color: $header-notification-dropdown-color;
  text-align: left;
  text-transform: none;
  list-style: none;
  background: $header-notification-dropdown-background-color;
  border-radius: $header-notification-dropdown-border-radius;
  border: solid $header-notification-dropdown-border-color
    $header-notification-dropdown-border-width;
  z-index: map-get($zindex, above-overlay);
  box-shadow:
    0 0 unit(0.25) palette(shadow, 0.3),
    0 unit(0.75) unit(2.5) palette(shadow, 0.5);
}

.header-notification-dropdown__container {
  max-height: $header-notification-dropdown-container-max-height;
}

.header-notification-dropdown__button-container {
  padding: $header-notification-dropdown-button-container-padding-top
    $header-notification-dropdown-button-container-padding
    $header-notification-dropdown-button-container-padding;
}
