.identity-document__uploader-container {
  display: flex;
}

.identity-document__uploader-file-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: unit(32);
  height: unit(32);
  text-align: center;

  @include respond-to(tablets-up) {
    width: unit(38);
    height: unit(38);
  }
}

.identity-document__document-delete-button {
  position: absolute;
  top: unit(2.5);
  right: unit(2.5);
}
