// scss-lint:disable ImportantRule

.u-font-size-small {
  @include font-size($font-size-small);
}

.u-font-size-none {
  font-size: 0 !important;
}

.u-font-size-micro {
  font-size: $font-size-micro !important;
}

.u-font-size-default {
  font-size: $font-size-default !important;
}

.u-font-size-medium {
  font-size: $font-size-medium !important;
}

.u-font-size-large {
  font-size: $font-size-large !important;
}

.u-font-size-big {
  font-size: $font-size-big !important;
}

.u-line-height-default {
  line-height: 1 !important;
}

.u-line-height-normal {
  line-height: $line-height-normal !important;
}

.u-line-height-medium {
  line-height: $line-height-medium !important;
}

.u-line-height-huge {
  line-height: $line-height-huge !important;
}

.u-text-transform-uppercase {
  text-transform: uppercase !important;
}

.u-line-through {
  text-decoration: line-through !important;
}
