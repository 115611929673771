// scss-lint:disable NestingDepth

.privacy-footer {
  min-height: $policy-footer-min-height;
}

.privacy-footer__content {
  position: absolute;
  bottom: 0;
  width: 100%;

  a,
  button {
    @include font-size($font-size-default);
    height: auto;
    padding: 0;
    margin-right: $policy-footer-link-margin;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: $font-weight-demibold;
    line-height: $policy-footer-link-line-height;
    color: palette(greyscale-level-3);
    text-decoration: underline;
    white-space: nowrap;
    cursor: pointer;
    border: 0;

    &:hover {
      text-decoration: underline;

      &::after {
        background: none;
        border: 0;
      }
    }
  }
}

.privacy-footer__content__links {
  padding: $policy-footer-links-padding 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.privacy-footer--static {
  min-height: 0;
}

.privacy-footer--static .privacy-footer__content {
  position: static;
}
