@use 'sass:math';

.media-select__grid {
  display: grid;
  grid-template-columns: repeat($media-select-columns, 1fr);

  @include respond-to(tablets-up) {
    grid-template-columns: repeat($media-select-columns-desktop, 1fr);
  }
}

.media-select__input {
  position: relative;
  padding-top: math.div(100%, $media-select-columns);

  @include respond-to(tablets-up) {
    padding-top: math.div(100%, $media-select-columns-desktop);
  }
}

.media-select__grid::-webkit-scrollbar {
  display: none;
}

.media-select__input-box {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.media-select__input-box-content,
.media-select__input-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
