.feedback {
  &--hidden {
    visibility: hidden;
  }

  &--collapsed {
    height: unit(9);
  }

  &--truncated p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.feedback-show-more {
  display: flex;
  position: absolute;
  top: unit(4.5);
  background: white;
  width: 100%;
}

.feedback-card-container {
  flex-shrink: 0;
  flex-grow: 0;
}

.seller-feedbacks__cards-container {
  display: flex;
  gap: unit(2);
  width: 100%;

  &--full-width .feedback-card-container {
    flex-basis: 100%;
    max-width: 100%;
  }

  &--half-width .feedback-card-container {
    flex-basis: calc((100% - unit(2)) / 2);
    max-width: calc((100% - unit(2)) / 2);
  }

  &--default .feedback-card-container {
    flex-basis: 40%;
    max-width: 40%;
  }

  &--half-width .feedback-card-container,
  &--default .feedback-card-container {
    @include respond-to(phones) {
      flex-basis: 85%;
      max-width: 85%;
    }
  }
}

.seller-feedbacks__cta {
  display: inline-block;
  max-width: 100%;

  @include respond-to(phones) {
    display: block;
  }
}
