$hov-vchoney-semibold-fontfamily: VCHoneySemiBoldDeck, 'Helvetica Neue', 'Helvetica-Neue', 'Arial',
  sans-serif;
$hov-page-content-width: unit(329);
$hov-color-1: #000000;
$hov-color-2: #ece2ce;
$hov-color-3: #1a4552;
$hov-color-4: #edffe9;

@mixin hov-full-width-section() {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

@mixin hov-content-container() {
  box-sizing: border-box;
  width: 100%;
  max-width: $hov-page-content-width;
  padding-right: unit(5);
  padding-left: unit(5);
  margin-right: auto;
  margin-left: auto;
}

@mixin full-width-background($bg-color) {
  width: 100vw;
  position: absolute;
  left: calc(-50vw + 50%);
  content: '';
  display: block;
  background-color: $bg-color;
  height: 100%;
  z-index: -1;
  top: 0;
}

@mixin hov-button() {
  font-family: $default-font-family;
  font-weight: 500;
  font-size: unit(3.5);
  line-height: unit(5.5);
  text-align: center;
  border-radius: unit(2);
  padding: unit(3) unit(7.5);
  text-decoration: none;
  display: block;
  width: fit-content;

  &:hover {
    text-decoration: none;
  }

  @include respond-to(desktops) {
    font-size: unit(4);
  }
}

.hov__landing-page-container {
  @include hov-content-container;
}

.hov__logo {
  box-sizing: border-box;
  margin: 0 auto;
  padding: unit(17.25) 0 unit(22);
  max-width: unit(64);

  @include respond-to(desktops) {
    max-width: unit(119.5);
    padding: unit(30) 0;
  }
}

.hov__introduction-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: unit(23.5);
  z-index: 1;
  position: relative;

  @include respond-to(desktops) {
    padding-bottom: unit(25);
  }
}

.hov__introduction-media-container {
  position: relative;
  width: 100%;

  &::after {
    @include full-width-background($hov-color-2);
    height: 300%;
    top: 50%;
  }
}

.hov__introduction-video-iframe,
.hov__introduction-image {
  border: 0;
  aspect-ratio: 1.75;
  width: 100%;
  max-width: unit(265);
  border-radius: unit(4);
  margin: 0 auto;
}

.hov__introduction-image img {
  border-radius: unit(4);

  @include respond-to(desktops) {
    border-radius: unit(5);
  }
}

.hov__introduction-title {
  color: $hov-color-1;
  font-family: $hov-vchoney-semibold-fontfamily;
  font-weight: 500;
  font-size: unit(8);
  line-height: unit(9);
  text-align: center;
  max-width: unit(69);
  margin: unit(12.5) auto 0;

  @include respond-to(tablets-up) {
    max-width: unset;
  }

  @include respond-to(desktops) {
    font-size: unit(12);
    line-height: unit(13);
    margin-top: unit(17.5);
  }
}

.hov__introduction-description {
  max-width: unit(209.75);
  margin: unit(7.5) auto unit(12.5);
  font-size: unit(3.5);
  line-height: unit(5);
  color: $hov-color-1;

  @include respond-to(desktops) {
    margin: unit(7.5) auto unit(13.75);
    font-size: unit(5);
    line-height: unit(7);
  }
}

.hov__introduction-cta {
  @include hov-button();

  background-color: $hov-color-3;
  color: palette(greyscale-level-6);

  @include respond-to(phones) {
    padding: unit(2.25) unit(5.5);
  }
}

.hov__brandnew-section {
  display: block;
  position: relative;
  z-index: 1;
  color: $hov-color-1;

  &::after {
    @include full-width-background($hov-color-2);
  }
}

.hov__brandnew-section-title {
  font-family: $hov-vchoney-semibold-fontfamily;
  font-weight: 500;
  font-size: unit(8);
  line-height: unit(9);
  text-align: center;
  margin-bottom: unit(10.5);

  @include respond-to(desktops) {
    font-size: unit(12);
    line-height: unit(10.5);
    margin-bottom: unit(18);
  }
}

.hov__brandnew-section-cards {
  display: flex;
  flex-direction: column;
  gap: unit(12.5);

  @include respond-to(tablets-up) {
    flex-direction: row;
    gap: unit(8.75);
  }
}

.hov__brandnew-section-card-image {
  overflow: hidden;
  width: 100%;
  border-radius: unit(5);
  aspect-ratio: 0.99;
  margin-bottom: unit(4);

  @include respond-to(tablets-up) {
    aspect-ratio: 0.81;
    margin-bottom: unit(5);
  }
}

.hov__brandnew-section-card-description {
  margin-top: 0;
  font-weight: 375;
  font-size: unit(3.5);
  line-height: unit(5);
  text-align: center;
  width: 100%;

  @include respond-to(tablets-up) {
    font-size: unit(4);
    line-height: unit(5.5);
    text-align: left;
    max-width: unit(100.5);
  }
}

.hov__brandnew-section-cta-container {
  padding: unit(12.5) 0 unit(23.5);
  display: flex;
  box-sizing: border-box;
  height: fit-content;
  justify-content: center;

  @include respond-to(desktops) {
    padding: unit(13.75) 0 unit(37.5);
  }
}

.hov__brandnew-section-cta {
  @include hov-button();

  background-color: $hov-color-3;
  color: palette(greyscale-level-6);
}

.hov__creators-section {
  position: relative;
  overflow-x: hidden;
  z-index: 1;

  @include hov-full-width-section;

  &::after {
    content: '';
    width: 100vw;
    height: 50%;
    position: relative;
    left: calc(-50vw + 50%);
    display: block;
    position: absolute;
    background: $hov-color-2;
    top: 0;
    z-index: -1;
  }
}

.hov__creators-section-inner {
  margin: 0 auto;
  width: 100%;
  padding-bottom: unit(28.75);

  @include respond-to(wide) {
    width: fit-content;
    display: flex;
    padding-bottom: unit(45);
  }
}

.hov__creators-title {
  font-family: $hov-vchoney-semibold-fontfamily;
  color: $hov-color-1;
  font-size: unit(8);
  line-height: unit(9);
  text-align: left;
  padding: unit(0) unit(5) unit(10.75);
  width: unit(75);

  @include respond-to(tablets) {
    width: 100%;
    min-height: 0;
  }

  @include respond-to(wide) {
    font-size: unit(12);
    line-height: unit(13);
    width: unit(88.75);
    padding: unit(13) unit(20.75) unit(0) unit(0);
    min-height: unit(45.25);
  }
}

.hov__creators-content {
  margin-left: 0;

  @include respond-to(wide) {
    margin-left: 5.5vw;
  }
}

.hov__creators-cards-wrapper {
  width: fit-content;
  display: block;
  gap: unit(2);
  margin-bottom: unit(0);
  padding: unit(0) unit(5);
  position: relative;
  z-index: 2;

  @include respond-to(wide) {
    overflow: hidden;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: unit(0);
    padding: 0;
  }
}

.hov__creators-card {
  width: unit(68.7);
  height: fit-content;

  @include respond-to(wide) {
    width: unit(100.5);
  }
}

.hov__creators-card-image {
  overflow: hidden;
  width: unit(68.75);
  height: unit(84.5);
  border-radius: unit(3.75);

  @include respond-to(wide) {
    width: unit(100.5);
    height: unit(123.75);
    border-radius: unit(5);
  }
}

.hov__creators-card-title {
  color: $hov-color-1;
  font-weight: 580;
  font-size: unit(4);
  line-height: unit(5);
  margin: 0;
  padding-top: unit(4);
  padding-bottom: unit(5);

  @include respond-to(wide) {
    font-size: unit(5);
    line-height: unit(6.5);
    padding-top: unit(5.25);
    padding-bottom: unit(5.5);
  }
}

.hov__creators-card-description {
  color: $hov-color-1;
  font-weight: 375;
  font-size: unit(4);
  line-height: unit(5);
  margin: 0;

  @include respond-to(desktops) {
    line-height: unit(5.5);
  }
}

.hov__creators-slider-wrapper-item {
  padding-right: unit(5.25);

  @include respond-to(wide) {
    padding-right: unit(8.75);
  }
}

.hov__creators-section-slider-container {
  width: 100%;
  position: relative;
  display: grid;
  grid-auto-flow: column;

  @include respond-to(desktops) {
    padding-left: unit(0);
  }
}

.hov__creators-section-slider-container::-webkit-scrollbar {
  display: none;
}

.hov__creators-slider-buttons-container {
  display: none;
  position: relative;
  z-index: 2;

  @include respond-to(wide) {
    padding-top: unit(6);
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
  }
}

.hov__creators-slider-wrapper-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: unit(7.5);
  height: unit(7.5);
  border-radius: 50%;
  border: solid unit(0.375) $hov-color-1;
  margin-right: unit(2);

  .web_ui__Icon__greyscale-level-1 svg {
    fill: $hov-color-1;
    color: $hov-color-1;
  }
}

.hov__creators-slider-wrapper-button.hov__creators-slider-nav-disabled {
  border: solid unit(0.375) palette(greyscale-level-3);

  .web_ui__Icon__greyscale-level-5 svg {
    fill: palette(greyscale-level-3);
    color: palette(greyscale-level-3);
  }
}

.hov__creators-slider-rotate-180 {
  transform: rotate(180deg);
}
.hov__closet-section {
  display: block;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  overflow-x: hidden;
}

.hov__closet-section-title {
  font-family: $hov-vchoney-semibold-fontfamily;
  font-size: unit(10);
  line-height: unit(11);
  color: $hov-color-1;
  text-align: center;
  padding: 0 unit(5) unit(12);

  @include respond-to(wide) {
    font-size: unit(19);
    line-height: unit(20.5);
    padding: 0 unit(5) unit(22.25);
  }
}

.hov__closet-section-cta-container {
  padding: unit(10.5) 0 unit(17.5);
  display: flex;
  box-sizing: border-box;
  height: fit-content;
  justify-content: center;

  @include respond-to(desktops) {
    padding: unit(13.75) 0 unit(37.5);
  }
}

.hov__closet-section-cta {
  @include hov-button();

  background-color: $hov-color-3;
  color: palette(greyscale-level-6);
}

.hov__closet-section-cards-wrapper {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: unit(0);
  padding-left: unit(5);

  @include respond-to(wide) {
    @include hov-content-container;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: unit(4);
  }
}

.hov__closet-section-slider-wrapper-item {
  padding-right: unit(5);

  @include respond-to(wide) {
    padding-right: 0;
  }
}

.hov__closet-section-product-card {
  height: fit-content;
  width: unit(36.75);

  a {
    text-decoration: none;
  }

  @include respond-to(wide) {
    width: unit(45.75);
  }
}

.hov__closet-section-product-card-image {
  position: relative;
  width: unit(36.75);
  height: unit(47.5);
  border-radius: unit(3.75);
  overflow: hidden;

  @include respond-to(wide) {
    width: unit(45.75);
    height: unit(59);
  }
}

.hov__closet-section-product-card-title {
  color: $hov-color-1;
  font-weight: 375;
  font-size: unit(3);
  line-height: unit(3.75);
  margin-top: unit(2);
  text-decoration: none;

  @include respond-to(wide) {
    font-size: unit(3.5);
    line-height: unit(4.5);
    margin-top: unit(3);
  }
}

.hov__closet-section-fave-icon {
  position: absolute;
  width: unit(7.25);
  height: unit(7.25);
  background: palette(greyscale-level-6);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: unit(2.5);
  bottom: unit(2.5);

  @include respond-to(wide) {
    width: unit(9.5);
    height: unit(9.5);
    right: unit(3.25);
    bottom: unit(2.5);
  }
}

.hov__bottom-section {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  color: $hov-color-4;
  padding: unit(18) unit(0) unit(15);
  z-index: 0;

  &::after {
    content: '';
    width: 100vw;
    height: 100%;
    position: relative;
    left: calc(-50vw + 50%);
    display: block;
    position: absolute;
    background: palette(primary-dark);
    bottom: 0;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  @include respond-to(desktops) {
    text-align: center;
    padding: unit(25) unit(0) unit(22.5);
  }
}

.hov__bottom-headline {
  font-family: $hov-vchoney-semibold-fontfamily;
  margin: 0;
  font-size: unit(10);
  line-height: unit(11);
  margin: 0 auto unit(5.5);
  font-weight: 500;
  text-align: center;
  max-width: unit(70);

  @include respond-to(tablets-up) {
    margin: 0 auto unit(11.25);
    max-width: unit(264);
    line-height: unit(21);
    font-size: unit(19);
    width: 100%;
  }
}

.hov__bottom-description {
  max-width: unit(68.75);
  color: $hov-color-4;
  font-size: unit(3.5);
  line-height: unit(5);
  margin: 0 auto unit(10.5);
  text-align: center;

  @include respond-to(tablets-up) {
    width: unit(212.5);
    max-width: 90%;
    font-size: unit(5);
    line-height: unit(7);
    margin-bottom: unit(11.5);
  }
}

.hov__bottom-cta {
  @include hov-button();
  background: $hov-color-4;
  color: palette(primary-dark);
  padding: unit(2.25) unit(7);
  margin: 0 auto;

  @include respond-to(desktops) {
    padding: unit(3.5) unit(11);
  }
}
