.image-preview {
  @include ios-smooth-scroll;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;

  @include respond-to(portables) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.image-preview__image {
  position: relative;
  flex: 0 0 auto;
}

.image-preview__image-remove {
  position: absolute;
  top: unit(2);
  right: unit(2);
}
