$ad-info-height: 20px;
$ad-leaderboard-max-width: 970px;
$ad-leaderboard-max-height: 250px + $ad-info-height;
$ad-leaderboard-mobile-max-width: 320px;
$ad-leaderboard-mobile-max-height: 100px + $ad-info-height;
$ad-inbetween-max-width: 970px;
$ad-inbetween-height: 90px + $ad-info-height;
$ad-inbetween-mobile-max-width: 320px;
$ad-inbetween-mobile-small-min-height: 100px + $ad-info-height;
$ad-sidebar-max-width: 336px;
$ad-sidebar-max-height: 600px + $ad-info-height;
