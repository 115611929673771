.user-grid {
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: 1fr;
  gap: 4px;

  &.wide {
    @include respond-to(desktops) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  @include respond-to(tablets-up) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.user-grid__item {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include respond-to(tablets-up) {
    flex-wrap: wrap;
  }
}
