.user-followers__content {
  display: flex;
  flex-direction: column;
  min-height: $user-followers-container-min-height;
}

.user-followers__content:has([data-loading="true"]) .user-grid__item {
  opacity: 0.5;
}

.user-followers__body {
  display: flex;
  flex-grow: 1;

  .user-grid {
    align-self: flex-start;
    width: 100%;
  }
}

.user-followers__body-state--center {
  align-self: center;
  width: 100%;
}

.user-followers__empty-animation {
  width: 96px;
  height: 96px;
}
