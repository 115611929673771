.body-content {
  position: relative;
  // TODO: instead of this, use sticky footer
  min-height: 600px;

  @include respond-to(phones) {
    min-height: 0;
  }
}

.body-content__sidebar {
  @extend .grid4;

  @include respond-to(desktops) {
    padding-right: $gutter !important;
  }

  @include respond-to(portables) {
    float: none !important;
    box-sizing: content-box;
    width: auto !important;
    padding: 0 $gutter;
    margin: (-$gutter) (-$gutter) $gutter;

    text-align: center;

    background-color: palette(greyscale-level-6);
    box-shadow: 0 1px 0 $header-shadow-color;
  }
}

.body-content__navigation {
  margin-top: 0;

  @include respond-to(portables) {
    display: table;
    box-sizing: content-box;
    width: 100%;
  }

  .nav-item {
    @include font-size($font-size-medium);

    display: block;
    margin: 15px 0;

    @include respond-to(portables) {
      display: table-cell;
    }

    &:first-child {
      margin-top: 0;
    }

    & .nav-item {
      @include font-size($font-size-default);
    }
  }

  .nav-link {
    display: block;
    line-height: 24px; // medium icon height
    color: palette(greyscale-level-3);

    @include respond-to(portables) {
      // leave icon only. Can't use negative text indent, because it moves pseudo elements
      font-size: 0;
      line-height: $header-height;
    }

    &:hover {
      text-decoration: none;
    }

    &.is-active {
      font-weight: 500;
      color: palette(greyscale-level-2);
    }
  }
}

.body-content__content {
  @extend .grid8;
  @extend %GRID-PORTABLES12;

  @include respond-to(phones) {
    padding-top: $half-gutter;
  }

  @include respond-to(desktops) {
    padding-left: $gutter !important;
  }
}
