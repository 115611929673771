.parcel-measurements-container {
  display: flex;
  flex-wrap: wrap;

  .parcel-block {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;

    .inputs {
      .web_ui__Cell__cell,
      .web_ui__Cell__wide,
      .web_ui__Input__input {
        @include respond-to(tablets-up) {
          padding-left: 0;
          padding-bottom: unit(4);
        }
      }
    }

    .image-container {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;

      img {
        width: unit(56.25);
        height: unit(37.5);
      }
    }

    @include respond-to(phones) {
      flex: 0 0 100%;

      img {
        max-width: unit(48);
        max-height: unit(32);
      }
    }
  }
}

.parcel-measurements-validation-error {
  .web_ui__Cell__cell,
  .web_ui__Cell__wide {
    padding-bottom: 0;
  }
}
