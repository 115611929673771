.referrals__title {
  @include font-size($referrals-title-font-size);

  font-weight: map-get(type(heading), font-weight);
  line-height: $line-height-medium;
  color: map-get(type(heading), color);
}

.referrals__how-it-works-title {
  font-size: $referrals-how-it-works-title-font-size;
  font-weight: map-get(type(title), font-weight);
  line-height: $referrals-how-it-works-title-line-height;
  color: map-get(type(title), color);
}

.referrals__registrations-content {
  @include respond-to(tablets-up) {
    height: $referrals-registrations-content-height;
    overflow: auto;
  }
}
