$layout-spacing: unit(8);
$layout-spacing-desktop: unit(12);

@mixin slider-wrapper-padding($direction) {
  padding-#{$direction}: $layout-spacing;

  @include respond-to(tablets-up) {
    padding-#{$direction}: $layout-spacing-desktop;
  }
}

.slider-wrapper-buttons-container {
  display: none;

  @include respond-to(tablets-up) {
    @include slider-wrapper-padding(top);

    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
  }
}

.slider-wrapper-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: unit(11);
  height: unit(11);
  border-radius: 50%;
  border: solid 1px palette(greyscale-level-1);
  margin-right: unit(6);
}

.slider-wrapper-button:hover {
  background-color: palette(greyscale-level-5);
}

.slider-wrapper-items-container {
  width: 100%;
  position: relative;
  display: grid;
  grid-auto-flow: column;
  @include slider-wrapper-padding(top);

  @include respond-to(desktops) {
    padding-left: unit(0);
  }
}

.slider-wrapper-items-container::-webkit-scrollbar {
  display: none;
}

.slider-wrapper-item {
  padding-right: unit(5);
}
