.nav-page-categories {
  display: block;
  margin: 10px 5px 20px;
  color: palette(primary-default);

  .nav-item {
    display: inline-block;
    width: 33.33%;
    padding-right: 20px;

    .nav-link {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    @include respond-to(phones) {
      width: 50%;

      .nav-link {
        @include ellipsis;
        position: relative;
        padding-right: 55px;
        display: block;

        &:after {
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -7px;
        }
      }
    }
  }
}

.brand-button {
  &:hover {
    text-decoration: underline;
  }
}

.search-results-ranking-modal {
  width: unit(120);

  body {
    height: 100vh;
  }
}

.catalog-items__loading {
  transition: opacity $default-animation-speed * 2 ease-in;
  opacity: 0.3;
}
