.content-block {
  position: relative;
  width: 100%;
}

.content-block__content {
  z-index: map-get($zindex, bump);
  padding: $content-block-vertical-spacing-mobile 0;

  @include respond-to(tablets-up) {
    padding: $content-block-vertical-spacing 0;
  }
}

.content-block__heading {
  width: 100%;
  margin-bottom: $content-block-heading-spacing;
}

.content-block__blocks {
  padding-top: $content-block-blocks-spacing;
}
