.landing__section {
  position: relative;

  @include respond-to(desktops) {
    padding-top: ($gutter * 2);
    padding-bottom: ($gutter * 2);
  }
}

.landing__section-content {
  @include clearfix;
  max-width: $landing-content-width;
  margin: 0 auto;
  line-height: $line-height-huge;
}

.landing__section-content--wide {
  max-width: $page-width;
}

.landing__section-entry {
  margin: 70px 20px;
  color: palette(greyscale-level-3);

  @include respond-to(tablets) {
    margin-top: 60px;
    margin-bottom: 50px;
  }

  @include respond-to(phones) {
    margin-top: 45px;
    margin-bottom: 35px;
  }
}
