.my-orders-filters {
  @include sticky;
  // this compensates the top navigation header
  top: unit(24);
  z-index: map-get($zindex, sticky);
  border-radius: radius(default) radius(default) 0 0;
  overflow: hidden;
}

.my-orders-content .web_ui__Card__overflowAuto {
  overflow: visible;
}
