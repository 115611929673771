.phishing-first-time-lister-education__conversation {
  display: flex;
  column-gap: unit(2);
  align-items: flex-end;
  padding: unit(6) unit(6) unit(6) unit(3);
  background-color: palette(greyscale-level-6);
  border-radius: radius(default);
}

.phishing-first-time-lister-education__avatar {
  flex-shrink: 0;
}

.phishing-first-time-lister-education__slide {
  flex-grow: 1;
  animation: fade-in-up 1s ease-in-out;
  overflow: auto;
}

.phishing-first-time-lister-education__messages-container {
  display: flex;
  flex-direction: column;
  row-gap: unit(3);
}

.phishing-first-time-lister-education__message {
  display: inline-block;
  background-color: palette(greyscale-level-6);
  border: 1px solid palette(greyscale-level-6);
  border-radius: radius(default);
  padding: unit(2);
  @include shadow(lifted);
  @include word-wrap();
  @include force-wrap();
}
