.c-modal__inline-content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: $modal-large-padding $modal-large-padding $modal-small-padding;
}

.c-modal__title {
  @include type($modal-title-text-type);
  text-align: center;

  & + .c-modal__body {
    margin-top: $modal-small-padding;
  }
}

.c-modal__body {
  @include type($modal-body-text-type);
}

.c-modal__actions {
  box-sizing: border-box;
  padding: $modal-small-padding;

  > * + *,
  > .c-modal__action + .c-modal__action {
    margin-top: $modal-space-between-actions;
  }
}

.c-modal__image {
  width: 100%;
}
