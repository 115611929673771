$one-line-height: unit(5.5);

.summary-max-lines-1 {
  max-height: $one-line-height;
  text-overflow: ellipsis;
  @include multiline-ellipsis(1);
}

.summary-max-lines-2 {
  max-height: $one-line-height * 2;
  text-overflow: ellipsis;
  @include multiline-ellipsis(2);
}

.summary-max-lines-3 {
  max-height: $one-line-height * 3;
  text-overflow: ellipsis;
  @include multiline-ellipsis(3);
}

.summary-max-lines-4 {
  max-height: $one-line-height * 4;
  text-overflow: ellipsis;
  @include multiline-ellipsis(4);
}

.summary-max-lines-5 {
  max-height: $one-line-height * 5;
  text-overflow: ellipsis;
  @include multiline-ellipsis(5);
}
