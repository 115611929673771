$feedback-modal-padding: unit(4);

.feedback-modal__title {
  width: 100%;
  padding: $feedback-modal-padding 0;
  text-align: center;
}

.feedback-input__title {
  padding: $feedback-modal-padding;
}

.feedback-modal__actions {
  padding: $feedback-modal-padding;
}
