.timeline__wrapper {
  padding-bottom: $timeline-wrapper-padding-bottom;
}

.timeline__item {
  display: flex;
}

.timeline__item--active {
  padding-top: $timeline-item-padding-top;
  background: palette(primary-default, 0.04);
}

.timeline__mark {
  display: flex;
  min-height: $timeline-mark-min-height;
  padding-right: $timeline-mark-padding-right;
  padding-left: $timeline-mark-padding-left;
  flex-direction: column;
  align-items: center;
}

.timeline__content {
  padding-right: $timeline-content-padding;
  padding-bottom: $timeline-content-padding;
}

.timeline__separator {
  border-left: $timeline-border-width solid $timeline-line-color;
  flex: 1;
}

.timeline__connector {
  display: flex;
  width: $timeline-connector-width;
  padding-left: $timeline-connector-padding-left;
  flex-direction: column;
  align-items: center;
}

.timeline__connector-line {
  height: $timeline-connector-height;
  border-left: $timeline-border-width solid $timeline-line-color;
}
