@use 'sass:math';

.row {
  @include clearfix;

  width: auto;
  max-width: none;
  min-width: 0;
  margin: 0 (-$half-gutter);

  @include respond-to(phones) {
    .container > & {
      margin-right: -(map-get($grid-gutters, phones));
      margin-left: -(map-get($grid-gutters, phones));
    }
  }
}

%GRID-PORTABLES12 {
  @include respond-to(portables) {
    // `.grid--default` class is unused but is required to increase specificity
    &:not(.grid--default) {
      width: 100%;
      left: auto;
      right: auto;
    }
  }
}

%GRID {
  @extend %GRID-PORTABLES12;

  position: relative;
  float: left;
  box-sizing: border-box;
  min-height: 1px;
  padding-right: $half-gutter;
  padding-left: $half-gutter;
}

@for $index from $grid-columns to 0 {
  .grid#{$index} {
    @extend %GRID;
    width: percentage(math.div($index, $grid-columns));
  }
}

.push2 {
  left: 16.66667%;
}

.push3 {
  left: 25%;
}
