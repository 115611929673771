.item-presentation-escrow-v2__order-item-content {
  display: flex;
  flex-direction: row;
}

.item-presentation-escrow-v2__order-item-texts {
  display: flex;
  flex-direction: column;
  max-width: 375px;
  padding-left: 8px;
}

.item-presentation-escrow-v2__order-item__title-wrapper {
  > span {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.item-presentation-escrow-v2__order-item__image-wrapper {
  > div {
    width: 128px;
    height: 170px;
  }
}

.item-presentation-escrow-v2__order-item__subtitles-wrapper {
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 128px; // same width as item-presentation-escrow-v2__order-item__image-wrapper
  }
}
