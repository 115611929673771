// TODO: Rework media queries to use `respond-to`
// after removing css from OneTrust's dashboard.
// Because breakpoints mismatch and it would cause
// weird overwrites here

// TODO: use `unit` helper to define sizes

#onetrust-consent-sdk #onetrust-pc-sdk {
  &.otPcCenter {
    border-radius: 12px;
  }

  #ot-fltr-cnt {
    box-shadow: 0 4px 16px rgba(var(--greyscale-level-1), 0.24);

    #clear-filters-handler {
      color: palette(primary-default);
    }

    .ot-label-txt {
      color: palette(greyscale-level-2);
    }
  }

  #vendor-search-handler {
    color: palette(greyscale-level-1);
  }

  #ot-pc-content,
  #ot-pc-lst {
    bottom: 100px;
    padding-bottom: 10px;
  }

  &.ot-ftr-stacked {
    #ot-pc-content,
    #ot-pc-lst {
      bottom: 160px;
    }

    &.otPcCenter {
      border-radius: 0;
    }
  }

  h1,
  h2,
  h3 {
    line-height: calc(4 / 3) !important;
  }

  h1,
  h2,
  h3,
  h4 {
    color: palette(greyscale-level-1) !important;
  }

  #ot-pc-desc,
  p,
  .ot-pur-vdr-count,
  .ot-host-desc,
  .ot-consent-hdr {
    color: palette(greyscale-level-2) !important;
  }

  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  button {
    -webkit-font-smoothing: antialiased;
  }

  button {
    border-radius: 6px;
  }

  .ot-accordion-layout > button,
  .ot-acc-cntr > button {
    border-radius: 0;
  }

  .ot-ven-link {
    text-decoration: underline;
  }

  .ot-plus-minus span {
    background-color: palette(primary-default) !important;
  }

  .ot-pc-scrollbar::-webkit-scrollbar-thumb {
    background-color: palette(greyscale-level-3);
  }

  &.ot-addtl-vendors #ot-lst-cnt:not(.ot-host-cnt) #ot-sel-blk,
  .ot-acc-cntr > .ot-acc-hdr {
    background-color: palette(greyscale-level-6);
  }

  #filter-btn-handler,
  .ot-chkbox input:checked ~ label::before {
    background-color: palette(primary-default);
  }

  #ot-anchor {
    border: 0;
  }

  .ot-chkbox {
    input:checked + label::before {
      border-color: palette(primary-default);
    }

    input + label::before {
      border-color: palette(greyscale-level-3);
    }
  }

  .category-vendors-list-handler + a::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 511.626 511.627'%3E%3Cg fill='%23007782'%3E%3Cpath d='M392.857 292.354h-18.274c-2.669 0-4.859.855-6.563 2.573-1.718 1.708-2.573 3.897-2.573 6.563v91.361c0 12.563-4.47 23.315-13.415 32.262-8.945 8.945-19.701 13.414-32.264 13.414H82.224c-12.562 0-23.317-4.469-32.264-13.414-8.945-8.946-13.417-19.698-13.417-32.262V155.31c0-12.562 4.471-23.313 13.417-32.259 8.947-8.947 19.702-13.418 32.264-13.418h200.994c2.669 0 4.859-.859 6.57-2.57 1.711-1.713 2.566-3.9 2.566-6.567V82.221c0-2.662-.855-4.853-2.566-6.563-1.711-1.713-3.901-2.568-6.57-2.568H82.224c-22.648 0-42.016 8.042-58.102 24.125C8.042 113.297 0 132.665 0 155.313v237.542c0 22.647 8.042 42.018 24.123 58.095 16.086 16.084 35.454 24.13 58.102 24.13h237.543c22.647 0 42.017-8.046 58.101-24.13 16.085-16.077 24.127-35.447 24.127-58.095v-91.358c0-2.669-.856-4.859-2.574-6.57-1.713-1.718-3.903-2.573-6.565-2.573z'/%3E%3Cpath d='M506.199 41.971c-3.617-3.617-7.905-5.424-12.85-5.424H347.171c-4.948 0-9.233 1.807-12.847 5.424-3.617 3.615-5.428 7.898-5.428 12.847s1.811 9.233 5.428 12.85l50.247 50.248-186.147 186.151c-1.906 1.903-2.856 4.093-2.856 6.563 0 2.479.953 4.668 2.856 6.571l32.548 32.544c1.903 1.903 4.093 2.852 6.567 2.852s4.665-.948 6.567-2.852l186.148-186.148 50.251 50.248c3.614 3.617 7.898 5.426 12.847 5.426s9.233-1.809 12.851-5.426c3.617-3.616 5.424-7.898 5.424-12.847V54.818c-.001-4.952-1.814-9.232-5.428-12.847z'/%3E%3C/g%3E%3C/svg%3E");
  }

  .ot-pc-logo {
    display: none;
  }

  #ot-pc-title {
    color: palette(greyscale-level-1);
    font-size: 1.125em;
  }

  #ot-pc-desc {
    font-size: 0.875em;
  }

  .ot-pur-vdr-count {
    font-size: 0.715em;
  }

  #accept-recommended-btn-handler,
  .save-preference-btn-handler {
    font-size: 1em;
    border-radius: 4px;
    padding: 12px 16px;
    min-width: 125px;
    letter-spacing: 0;
    border-radius: 6px;
  }

  .ot-btn-container button {
    font-size: 1em;
    letter-spacing: 0;
  }

  .ot-pc-footer-logo {
    background: none;
  }

  .ot-pc-footer-logo a {
    height: 20px;
    width: 136px;
    margin-top: 0;
    margin-right: 20px;
  }

  #cookie-preferences {
    border-top: none;
    margin-top: 16px;
    padding-top: 16px;
  }

  .ot-accordion-layout .accordion-header {
    padding: 16px;
    width: calc(100% - 32px);
  }

  .ot-accordion-layout.category-item .ot-accordion-group-pc-container {
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% - 32px);
  }

  .category-item p:first-child {
    padding-top: 0;
  }

  .ot-accordion-layout.category-item:first-of-type {
    margin-top: 16px;
  }

  #pc-title,
  h3,
  h4,
  h5,
  h6 {
    color: palette(greyscale-level-1);
  }

  #pc-title {
    font-size: 24px;
  }

  h4.category-header {
    font-size: 1em;
    padding-top: 4px;
  }

  #pc-policy-text,
  .ot-accordion-layout .ot-accordion-group-pc-container {
    font-size: 0.938em;
  }

  .ot-cat-grp .ot-always-active {
    color: palette(greyscale-level-2);
    padding-top: 4px;
  }

  .onetrust-pc-sdk .ot-accordion-layout .ot-always-active-group > .ot-arrow-container {
    top: 1px;
  }

  ul.cookie-subgroups li p,
  ul.cookie-subgroups li h6 {
    max-width: 90%;
  }

  ul.cookie-subgroups li p.cookie-subgroups-description-legal {
    margin-top: 0;
  }

  .category-item .ot-switch.ot-toggle input:focus + .ot-switch-label,
  input[type='checkbox']:focus + .accordion-header {
    outline: none !important;
  }

  .ot-accordion-pc-container.accordion-text {
    background-color: palette(greyscale-level-6);
  }

  .accordion-text .cookie-subgroup-toggle .ot-switch.ot-toggle {
    background-color: transparent;
  }

  .ot-switch {
    width: 36px;
  }

  .ot-switch-nob {
    margin: 0px;
    background: palette(greyscale-level-3);
    border: none;
  }

  .ot-switch-nob:before {
    border: 1px solid palette(greyscale-level-4);
    background-color: palette(greyscale-level-6);
    left: 1px;
  }

  .ot-tgl input:checked + .ot-switch .ot-switch-nob {
    background-color: palette(primary-default);
    border: none;
  }

  .ot-tgl input:checked + .ot-switch .ot-switch-nob:before {
    background-color: palette(greyscale-level-6);
    border-color: palette(primary-default);
    -webkit-transform: translateX(11px);
    -ms-transform: translateX(11px);
    transform: translateX(11px);
  }

  .back-btn-handler {
    outline: none;
  }

  .vendor-info {
    width: 200px;
  }

  .vendor-title {
    width: 200px;
    max-width: 200px;
    margin-bottom: 0 !important;
  }

  ul li h3 {
    font-size: 0.938em;
    color: palette(greyscale-level-1) !important;
  }

  .vendor-info .vendor-privacy-notice {
    display: inline-block;
  }

  @media only screen and (max-width: 476px) {
    .ot-btn-container {
      padding: 0 10px;
      margin: 0;
      width: calc(100% - 20px);
    }

    .ot-pc-footer-logo {
      text-align: center;
    }

    .ot-pc-footer-logo a {
      margin-right: 0;
    }
  }

  [data-theme='dark'] & {
    @include shadow(elevated);
    background-color: palette(greyscale-level-6);

    #ot-search-cntr,
    .ot-switch.ot-toggle,
    .ot-grp-hdr1 .checkbox,
    #ot-pc-title:after,
    #ot-sel-blk,
    #ot-fltr-cnt,
    #ot-anchor,
    .ot-acc-grpcntr.ot-acc-txt,
    .ot-acc-txt .ot-subgrp-tgl .ot-switch.ot-toggle {
      background-color: palette(greyscale-level-6);
    }

    p,
    #ot-ven-lst .ot-ven-opts p,
    #ot-pc-desc,
    .ot-li-title,
    .ot-sel-all-hdr span,
    #ot-host-lst .ot-host-info,
    #ot-fltr-modal #modal-header,
    .ot-checkbox label span,
    #ot-pc-lst #ot-sel-blk p,
    #ot-pc-lst #ot-lst-title span,
    #ot-pc-lst .back-btn-handler p,
    #ot-pc-lst .ot-ven-name,
    #ot-pc-lst #ot-ven-lst .consent-category,
    .ot-leg-btn-container .ot-inactive-leg-btn,
    .ot-label-status,
    .ot-chkbox label span,
    #clear-filters-handler,
    .ot-cat-grp .ot-always-active {
      color: palette(greyscale-level-3);
    }

    #ot-pc-title,
    #pc-title,
    h3,
    h4,
    h5,
    h6 {
      color: palette(greyscale-level-1);
    }

    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(
        .ot-remove-objection-handler
      ):not(.ot-obj-leg-btn-handler):not([aria-expanded]):not(.ot-link-btn),
    .ot-leg-btn-container .ot-active-leg-btn {
      background-color: palette(primary-default);
      border-color: palette(primary-default);
      color: palette(greyscale-level-6);
    }

    .ot-accordion-layout.ot-cat-item,
    .ot-accordion-layout.ot-cat-item:first-of-type {
      border-color: palette(greyscale-level-5) !important;
    }

    .ot-pc-footer,
    .ot-acc-grpcntr .ot-subgrp-cntr {
      border-color: palette(greyscale-level-5);
    }

    .ot-cat-item > button:focus,
    .ot-acc-cntr > button:focus,
    li > button:focus {
      outline: none;
    }

    .privacy-notice-link,
    .category-vendors-list-handler,
    .category-vendors-list-handler + a,
    .category-host-list-handler,
    .ot-ven-link,
    #ot-host-lst .ot-host-name a,
    #ot-host-lst .ot-acc-hdr .ot-host-expand,
    #ot-host-lst .ot-host-info a {
      color: palette(primary-default);
    }

    .category-vendors-list-handler + a::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 511.626 511.627'%3E%3Cg fill='%233AC1C8'%3E%3Cpath d='M392.857 292.354h-18.274c-2.669 0-4.859.855-6.563 2.573-1.718 1.708-2.573 3.897-2.573 6.563v91.361c0 12.563-4.47 23.315-13.415 32.262-8.945 8.945-19.701 13.414-32.264 13.414H82.224c-12.562 0-23.317-4.469-32.264-13.414-8.945-8.946-13.417-19.698-13.417-32.262V155.31c0-12.562 4.471-23.313 13.417-32.259 8.947-8.947 19.702-13.418 32.264-13.418h200.994c2.669 0 4.859-.859 6.57-2.57 1.711-1.713 2.566-3.9 2.566-6.567V82.221c0-2.662-.855-4.853-2.566-6.563-1.711-1.713-3.901-2.568-6.57-2.568H82.224c-22.648 0-42.016 8.042-58.102 24.125C8.042 113.297 0 132.665 0 155.313v237.542c0 22.647 8.042 42.018 24.123 58.095 16.086 16.084 35.454 24.13 58.102 24.13h237.543c22.647 0 42.017-8.046 58.101-24.13 16.085-16.077 24.127-35.447 24.127-58.095v-91.358c0-2.669-.856-4.859-2.574-6.57-1.713-1.718-3.903-2.573-6.565-2.573z'/%3E%3Cpath d='M506.199 41.971c-3.617-3.617-7.905-5.424-12.85-5.424H347.171c-4.948 0-9.233 1.807-12.847 5.424-3.617 3.615-5.428 7.898-5.428 12.847s1.811 9.233 5.428 12.85l50.247 50.248-186.147 186.151c-1.906 1.903-2.856 4.093-2.856 6.563 0 2.479.953 4.668 2.856 6.571l32.548 32.544c1.903 1.903 4.093 2.852 6.567 2.852s4.665-.948 6.567-2.852l186.148-186.148 50.251 50.248c3.614 3.617 7.898 5.426 12.847 5.426s9.233-1.809 12.851-5.426c3.617-3.616 5.424-7.898 5.424-12.847V54.818c-.001-4.952-1.814-9.232-5.428-12.847z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  button.ot-pgph-link {
    float: left;
  }

  .ot-host-hdr a {
    color: palette(primary-default);

    &:hover {
      color: palette(primary-dark);
    }
  }
}
