fieldset {
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  min-width: 0;

  .form-actions & {
    max-width: 100%;
  }
}

legend {
  text-align: left;
  @include font-size($font-size-hero);
  line-height: initial;
  font-weight: bold;

  padding: 0;
  margin: 0;
  display: block;
  width: 100%;

  // User border instead of margin for opera
  border-bottom: solid $gutter transparent;
}

label {
  &.error {
    color: palette(warning-default) !important;
  }
}

#{$inputs} {
  @include font-size($input-font-size);
  box-sizing: border-box;
  font-family: $default-font-family;
  font-weight: normal;
}

#{$text-inputs},
#{$button-inputs} {
  -webkit-appearance: none;
}

#{$button-inputs} {
  cursor: pointer;
}

button {
  @include font-size(inherit);

  display: inline-block;
  margin: 0;
  padding: 0;

  line-height: inherit;
  color: inherit;
  vertical-align: baseline;

  background: none;
  border: 0;

  cursor: pointer;
  overflow: visible;

  -webkit-user-select: none;
  -moz-user-select: none;
}

textarea,
input[type='file'],
input[type='text'],
input[type='number'],
input[type='password'] {
  color: $input-text-color;
  @include font-size($font-size-default);

  border: solid 1px $input-border-color;
  background-color: $input-background-color;

  outline: none;

  &:focus,
  &:active {
    outline: none;
    border-color: palette(primary-default);
  }

  &.disabled {
    border: solid 1px $color-border-light;
    color: palette(greyscale-level-5);
  }
}

input {
  &.error {
    border-color: $invalid-input-message-border-color;
  }
}

.disabled[readonly] {
  opacity: 0.5;
}
