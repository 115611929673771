.closet-container {
  display: block;
  box-sizing: border-box;
  width: 100%;

  @include respond-to(tablets-up) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.closet-container__item {
  width: 100%;

  @include respond-to(tablets-up) {
    width: 46%;
  }
}

.closet-container__item-horizontal-scroll {
  width: 100%;
}
