// Navigation list defaults (default styles)

$sidebar-navigation-icon-size: 24px;

.nav {
  @include font-size(0); // prevent whitespace
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0;
  margin: 0;
  line-height: 0;
  vertical-align: middle;
  list-style: none;
}

.nav-item {
  @include font-size($font-size-default); // reset font-size: 0 on parent;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  line-height: $default-nav-line-height;
  color: inherit;
  vertical-align: inherit;
  list-style: inherit;

  @include respond-to(phones) {
    line-height: $responsive-nav-line-height;
  }
}

.nav-link {
  display: inline-block;
  line-height: inherit;
  color: inherit;
  width: -moz-fit-content;
  width: fit-content;
}

.nav-links {
  color: inherit;
  text-decoration: none;

  &:active,
  &:visited &:hover,
  &:link {
    text-decoration: none;
  }
}
