.followed-users__content {
  display: flex;
  flex-direction: column;
  min-height: $followed-users-container-min-height;
}

.followed-users__body {
  display: flex;
  flex-grow: 1;

  .user-grid {
    align-self: flex-start;
    width: 100%;
  }
}

.followed-users__body-state--center {
  align-self: center;
  width: 100%;
}
