.tear {
  position: relative;
  background: inherit;

  &::before,
  &::after {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: $tear-height;
    background: inherit;
    content: '';
    -webkit-mask: $tear-horizontal-mask;
    mask: $tear-horizontal-mask;
  }

  &::before {
    top: -($tear-height);
  }

  &::after {
    bottom: -($tear-height);
    transform: scaleY(-1);
  }
}

.tear--top {
  &::after {
    display: none;
  }
}

.tear--bottom {
  &::before {
    display: none;
  }
}
